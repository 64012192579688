@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap");
a:hover,
a:focus {
  color: $baseColor;
}
.baseColor {
  color: $baseColor;
}

.bim-rl {
  border-top: 3px solid;
  border-image: linear-gradient(to right, #f7f7f1, var(--theme-color)) 1 0 0 0;
}
.bim-lr {
  border-top: 3px solid;
  border-image: linear-gradient(to left, #f7f7f1, var(--theme-color)) 1 0 0 0;
}

.form-check-inline .form-check-input {
  cursor: pointer !important;
}

.op-50 {
  opacity: 50%;
}

.return-link {
  cursor: pointer;
  text-decoration: underline !important;
  color: $baseColor !important;
}

.hi-color {
  color: var(--theme-hi-color);
}
.suc-color {
  color: var(--primary-color-green);
}
.ship-Text {
  background: var(--theme-hi-color);
  color: $white;
  padding: 0.5rem 0.2rem;
}
.due-Text {
  background: var(var(--theme-hi-color));
  color: $dark1;
  padding: 0.3rem 0.4rem;
  font-size: 18px;
  font-weight: 700;
}
.btn-secondary {
  height: 36px;
}

.form-control:focus {
  color: $dark1;
  border-color: var(--theme-color);
}
.auth-form {
  .form-group {
    margin-bottom: 0.5rem !important;
    label {
      margin-bottom: 0 !important;
    }
  }
}
.timeBox {
  color: $white;
  background-color: $dark1;
  padding: 2px 6px;
  border-radius: 8px;
}
.exOffBox {
  object-fit: contain;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.exPriceBox {
  position: absolute;
  bottom: 5px;
  left: 25%;
  .bt {
    max-width: 100%;
    height: 20px;
    padding: 0 8px;
    overflow: hidden;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: var(--primary-color-dark); /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
    border-radius: 10px;
  }
}
.mobile_header .input-group .form-control {
  border: 0;
  outline: none;
}
.mobile_header .input-group .form-control:focus {
  outline: none !important;
  border: none !important;
}

.link-underline {
  box-shadow: 0 1px 0 0 var(--theme-color);
}

.text-primary {
  color: var(--theme-color);
}
.text-orange {
  color: var(--theme-hi-color) !important;
}

.text-secondary {
  color: $dark1 !important;
}
.t-middle {
  text-align: center;
}

.sticky-wrapper {
  display: none;
}

.custom_widget_list {
  li {
    border-bottom: 1px solid #eee;

    &:last-child {
      border: none;
    }

    a {
      display: block;
      color: #555;
      padding: 1rem 1rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.5;

      &:hover {
        background-color: #eee;
        color: var(--theme-color);
      }
    }
  }
}

.custom_widget_list li a.active {
  color: #3399ff;
}

.product_summary_table td {
  padding: 6px 10px;
}

.linear-primary-bg {
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
}

#Category-select {
  color: $dark1;
}

.btn-default {
  height: 36px;
  border: none;
  padding: 0 1rem;
  border-radius: 4px;
  color: $baseFontColor;
  padding: 0.8rem 0.8rem;
  border: none;
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
    background: -webkit-linear-gradient(
      to left,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
    transition: all 0.4s;
  }
}
.btn-default-sm {
  border: none;
  border-radius: 5px;
  color: $baseFontColor;
  padding: 0.3rem 0.5rem;
  border: none;
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
    background: -webkit-linear-gradient(
      to left,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
    transition: all 0.4s;
  }
}
.btn-default-outline {
  height: 36px;
  border: 1px solid $baseColor;
  padding: 0 1rem;
  border-radius: 4px;
  color: $baseColor;
  padding: 0.8rem 0.8rem;
  border: none;
  background: transparent;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
    background: -webkit-linear-gradient(
      to left,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
    transition: all 0.4s;
  }
}

.input-group {
  //max-width: 130px;

  .form-control {
    height: 39px;
    padding: 0.3rem 0.3rem;
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: 400;
    color: #444;
  }

  .btn-default {
    padding: 0;
  }

  .input-group-prepend {
    .btn-default {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      @media only screen and (max-width: 767px) {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
  }

  .input-group-append {
    .btn-default {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      @media only screen and (max-width: 767px) {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}

.btn-refund {
  font-size: 14px !important;
  padding: 0 1rem;
}
.cart-remove {
  color: var(--theme-color);
  margin-left: 1rem;
  font-weight: 400;
  font-size: 1.4rem;

  &:hover,
  &:focus {
    color: var(--theme-color);
  }
}

.nav-item-text {
  font-size: 1.6rem;
  display: inline-flex;
  vertical-align: middle;
}

.dropdown-menu.nav_customer_menus {
  padding: 1rem 0 0 0;
  width: 100%;

  .dropdown-item {
    color: #0c0c0c;
    display: block;
    padding: 6px 10px;
    font-size: 1.4rem;
    border-bottom: 1px solid #ddd;

    &:hover {
      color: var(--theme-color);
      background-color: rgba(221, 221, 221, 0.35);
    }

    :last-child {
      padding-bottom: 0;
    }
  }
}

.stick_footer_nav {
  min-height: 40px;
  background-color: $white;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 8px rgba(205, 205, 205, 0.6);

  a {
    padding: 0;
  }

  .sticky_nav_icon {
    font-size: 2.5rem;
    line-height: 1.8;
    color: var(--theme-color);
  }
}

.mobile_header {
  padding-bottom: 10px;

  .logo {
    margin: 12px 0;
    margin-bottom: 8px;

    img {
      height: 45px;
    }
  }

  .input-group .form-control {
    height: 40px;
    padding: 0.85rem 2rem;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    color: #444;
  }

  .label_btn {
    margin-bottom: 0;
    cursor: pointer;
  }

  .btn-search {
    background-color: $baseColor;
    color: $white;
    padding: 0.2rem 1.2rem;
    font-size: 1.9rem;
    border-radius: 0px 10px 10px 0px;
  }
  .btn-camera {
    background-color: $white;
    color: $dark1;
    padding: 0.2rem 1.2rem;
    font-size: 1.9rem;
    border-radius: 10px 0 0 10px !important;
    &:hover {
      background-color: $baseColor;
      color: $white;
    }
    &:active {
      background-color: $baseColor;
      color: $white;
    }
  }
}

.bankDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.bankLabel {
  margin-left: 2.5rem;
}
.product_list_container {
  background-color: $white;
  padding: 1rem;
}

.product-sidebar {
  background-color: transparent;
  padding: 0;
  max-height: 92rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
.product-sidebar::-webkit-scrollbar {
  width: 0;
}

.ProductConfiguredItems {
  max-height: 220px;
  overflow-y: auto;
  margin-bottom: 0.3rem !important;
  scrollbar-width: none;
}

.tableFixHead {
  overflow-y: auto;
  max-height: 220px;
  margin-bottom: 0.4rem !important;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  background: #f4f4f4;
}
table {
  border-collapse: collapse;
  width: 100%;
}
// ::-webkit-scrollbar {
//   width: 0;
// }

.maxQuantityText {
  color: #444;
  font-size: 1.1rem;
  margin: 0;
}

.btn_only_add {
  border-radius: 4px;
  padding: 2px 8px;

  i {
    margin: 0;
  }
}

.modal_custom {
  background: rgba(0, 0, 0, 0.3);

  .modal-body {
    max-height: 400px;
    overflow-y: auto;
  }

  .modal-title {
    margin-bottom: 0;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  .modal-dialog {
    max-width: 575px;
  }

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

.address_card {
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  padding: 1rem;
  position: relative;

  .btn_ship_here {
    position: absolute;
    right: 6%;
    bottom: 40%;
    padding: 0 5px;
    background: var(--theme-color);
    color: $white;
    border-radius: 4px;
    cursor: pointer;
  }
}

.swal-button {
  background-color: var(--button-bg-color);

  &:focus {
    box-shadow: none;
  }

  &.swal-button--cancel {
    color: #555;
    background-color: #efefef;
  }
}

.swal-button:not([disabled]):hover {
  background-color: var(--button-bg-hover-color);
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #e8e8e8;
}

.payment_card {
  background: $white;
  padding: 0 3rem;
}

.categories_box {
  text-align: center;

  a {
    display: block;
    padding: 10px;
    background-color: $white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

    img {
      display: inline-block;
      width: 100%;
      max-width: 150px;
      height: auto;
    }

    span {
      text-transform: capitalize;
      margin-top: 5px;
      display: block;
      color: #333;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 400;
    }

    &:hover {
      span {
        color: var(--theme-color);
      }
    }
  }
}

.video_thumb {
  position: relative;

  .videoPlayerIcon {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    z-index: 10000;
    background: transparent;
    color: var(--theme-color);
    text-align: center;
    font-size: 2.5rem;
  }
}

.more-to-love {
  text-align: center;
  border-radius: 4px;
  border: 0.1rem solid #ebebeb;

  .title {
    width: 100%;
    font-size: 20px;
    margin-top: 15px !important;
    font-weight: bold;
  }

  .love-left,
  .love-right {
    font-size: 30px;
  }
}

.menuBlock {
  position: relative;
}

// my styles
.ow {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  hyphens: auto !important;
}
.t-up {
  text-transform: uppercase;
}
.m-card {
  background: $white;
  border: 1px solid #eee;
  border-radius: 8px;
}
.mb15 {
  margin-bottom: 1.5rem;
}
.mt05 {
  margin-top: 0.5rem !important;
}
.mb05 {
  margin-bottom: 0.5rem;
}
.flex {
  display: flex;
}
.flexWrap {
  flex-wrap: wrap;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCol {
  flex-direction: column;
}
.flexBetween {
  display: flex;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  justify-content: center;
}
.fCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexMiddle {
  display: flex;
  align-items: center;
}
.bold {
  font-weight: 700;
}
.fs-18 {
  font-size: 18px;
}
.fs-18 {
  font-size: 18px;
}

.darkBtn {
  background-image: none !important;
  background-color: #1d262d !important;
}
.fs14 {
  font-size: 14px;
}
.dealsSold {
  color: #999;
}
.dealsBtn {
  height: 20px;
  font-size: 15px;
  font-weight: 700;
  color: $white;
  background-image: linear-gradient(270deg, var(--primary-color-light), var(--primary-color-dark));
  border-radius: 6px;
  margin: 0 5px;
  padding: 0 6px;

  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
}

.order-title {
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
  padding: 2px 8px;
  border-radius: 15px;
  text-transform: uppercase;
  color: $white;
  font-size: 1.6rem;
}
.slick-prev:before,
.slick-next:before {
  color: $darkOrange;
}
.slick-next {
  right: -10px;
}
.slick-prev {
  left: -10px;
}

.statusBoxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}
.status-title {
  color: $dark1;
  font-size: 10px;
  margin: 0 !important;
  font-weight: 700 !important;
  padding-top: 0.5rem;
}
.status-update {
  color: $dark1;
  font-size: 10px;
  margin: 0 !important;
}

.status-img {
  width: 35px;
}

.check-st {
  color: green;
  margin-top: 3px;
}
.border-image-clip-path {
  margin: auto;
  border: 3px solid;
  border-image: linear-gradient(45deg, gold, deeppink) 1;
  clip-path: inset(0px round 3px);
  animation: huerotate 6s infinite linear;
  filter: hue-rotate(360deg);
}
// .border-image-clip-path {
//   margin: auto;
//   border: 9px solid;
//   border-image: linear-gradient(45deg, gold, deeppink) 1;
//   clip-path: inset(0px round 10px);
//   animation: huerotate 6s infinite linear;
//   filter: hue-rotate(360deg);
// }

@keyframes huerotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rorate(360deg);
  }
}

.hov-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.br-1 {
  border: 1px solid lightgray;
}
.proImg {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-bottom: 10px;
}
.proTop {
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $white;
  overflow: hidden;
}
.dealsPrice {
  font-size: 18px;
  color: #222;
  font-weight: 800;
  line-height: 20px;
}
.profile {
  font-size: 22px;
  margin-bottom: 7px;
}
.app-in {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h3 {
    font-weight: bold;
    margin: 0.5rem 0px 1rem;
    color: $white;
    font-size: 18px;
  }
  p {
    font-size: 14px;
    margin-right: "1rem";
    margin-bottom: 0.5rem;
    color: $white;
  }
}
.cardHighlight {
  // background-image: linear-gradient(90deg, var(--primary-color-light) 50%, #ffda72);
  background-image: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
}

.homeLogin-btn {
  display: block;
  float: left;
  width: 100%;
  color: $white;
  border-radius: 18px;
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
  padding: 3px 15px;
  outline: none;
  border: none !important;
}
.homeReg-btn {
  display: block;
  float: left;
  width: 100%;
  color: #222;
  border-radius: 18px;
  background: #f9f9f9;
  padding: 3px 15px;
}
.mb05 {
  margin-bottom: 0.5rem;
}
.mr05 {
  margin-right: 0.5rem;
}
.mr1 {
  margin-right: 1rem;
}
.mr01 {
  margin-right: 0.5rem;
}
.ml01 {
  margin-left: 0.5rem;
}
.mt1 {
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}
.pd-btn {
  margin: 0.5rem 0;
}
.bt {
  height: 36px;
  border: none;
  padding: 0 1rem;
  border-radius: 4px;
  color: $baseFontColor;
  padding: 0.8rem 0.8rem;
  border: none;
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
  }
}
.bt:hover {
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
    background: -webkit-linear-gradient(
      to left,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
    transition: all 0.4s;
  }
}
.imageBt {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  height: 44px;
  border-radius: 4px;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
    background: -webkit-linear-gradient(
      to left,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
    transition: all 0.4s;
  }
}
.ripple {
  background-position: 100%;
  transition: all 0.2s;
}
.modal-promo {
  background: rgba(0, 0, 0, 0.3);
}
.promo {
  box-shadow: rgb(0, 0, 0) 0px 0px 8px !important;
}
// .disabled {
//   color: $baseFontColor;
//   background: var(--primary-color-dark); /* fallback for old browsers */
//   background: -webkit-linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark)); /* Chrome 10-25, Safari 5.1-6 */
//   background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
//   &:hover {
//     background: -webkit-linear-gradient(to right, var(--primary-color-dark), var(--primary-color-light)); /* Chrome 10-25, Safari 5.1-6 */
//     background: linear-gradient(to right, var(--primary-color-dark), var(--primary-color-light));
//   }
// }

.pl-head {
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: 600;
    margin: 0.75rem 0 0.475rem;
    font-size: 14px;
  }
}

.air-p {
  font-size: 14px;
  padding: 0.275rem 0px;
  margin-bottom: 0.5rem;
}
.sea-p {
  margin-top: 0.75rem;
  font-size: 14px;
  background: rgb(0, 0, 0);
  padding: 0.275rem 0.6rem;
  color: rgb(255, 255, 255);
  border-radius: 4px;
}
.airInput {
  padding: 6px 12px;
  height: 36px;
  font-size: 14px;
  font-weight: 600;
  background: $white;
  border-radius: 4px;
  border: 1px solid #ecd38e;
}
.saleChinaOff {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// banner
.bannerSection {
  display: grid;
  grid-template-columns: 1fr 470px;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.promotions {
  background: $white;
  border: 1px solid #eee;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.slImg,
.promotions img {
  height: 470px;
}

// home box
.homeBoxContainer {
}
// .homeBoxContainer {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   grid-gap: 0.65rem;
//   gap: 0.65rem;
//   width: 100%;
//   border-radius: 4px;
// }
.homeComp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $white;
  padding: 2rem 1rem;
  border-radius: 4px;
  align-items: center;
  img {
    width: 65px;
    height: 65px;
  }
  h3 {
    font-weight: 700;
    margin-bottom: 0.25rem;
  }
  button {
    margin-top: 1rem;
    outline: none;
    border: none;
  }
  .homeLogin-btn {
    font-size: 12px;
    padding: 1px 5px;
    width: 80%;
  }
}

.custom-spinner {
  width: 5rem !important;
  height: 5rem !important;
  color: (--theme-color) !important;
  background-color: (--theme-color);
}

// category
.topCatContainer {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid rgb(238, 238, 238);
}
.topAr1 {
  width: 26px;
  height: 26px;
  padding: 8px;
  align-items: center;
  background-color: rgb(0, 0, 0);
  border-radius: 16px;
  justify-content: center;
  cursor: pointer;
}
.topAr2 {
  width: 26px;
  height: 26px;
  padding: 8px;
  align-items: center;
  background-color: rgb(0, 0, 0);
  border-radius: 16px;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}
.topTitle {
  font-size: 15px;
  margin: 0.75rem 0 0.475rem;
}
.responsiveOverflow {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  width: 100%;
  display: flex;
  overflow: hidden;
}
.sellerCategoryContainer {
  display: inline-flex;
  .category {
    color: $dark1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    border-right: 1px solid rgb(238, 238, 238);
    padding: 0.5rem 1rem;
    width: 156px;
    .cat-img {
      width: 40px;
      height: 80px;
      object-fit: contain;
    }
    span {
      height: 48px;
      text-align: center;
      font-size: 13px;
      text-transform: capitalize;
      font-weight: 600;
      word-break: keep-all;
    }
  }
}
.shippingBoxContainer {
  display: flex;
}
.shippingBox {
  background-color: #f4f4f4;
  flex: 1 1;
  padding: 12px;
  border-radius: 4px;
  p {
    font-weight: 700;
    margin-bottom: 0 !important;
  }
}

.card-body {
  padding: 1.8rem 1.2rem;
  border: none;
  border-radius: 0;
}
.seaTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  tbody {
    position: relative;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #f0f0f0;
    color: $dark1;
  }
  tr {
    background-color: $white;
  }
  tr:nth-child(odd) {
    background-color: $white;
  }
  tr,
  td,
  th {
    text-align: center;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}
.groupData div {
  margin-top: 0.5rem;
  b {
    font-weight: 600;
    font-size: 16px;
  }
  span {
    text-transform: capitalize;
    font-weight: 500;
  }
  a:hover {
    color: $dark1;
  }
}

.product-details-tab .nav.nav-pills .nav-link {
  border: 0.2rem solid var(--theme-color);
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    margin: 0.5rem;
  }
}
.product-details-tab .nav.nav-pills .nav-link.active {
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: $dark1;
}
// feat cat
.firstFeatCatBox {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.firstFeatCat {
  margin-left: 10px;
  padding-top: 10px;
}
.fcatName {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 10px !important;
}
.fcatIm {
  height: 120px;

}
.fcat1Im {
  height: 73px;
}
.firstIm {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.singleFeatCat {
  background-color: white;
  padding: 5px 7px;
  border-radius: 6px;
}

.singleVariant {
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
}

// product price range
.ranges {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 0;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  text-align: center;
}
.range {
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  .amount {
    font-size: 17px;
    line-height: 1.5rem;
    font-weight: 700px;
  }
  .piece {
    font-size: 14px;
    display: block;
    line-height: 1.5rem;
  }
}
.rangeActive {
  background-image: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
  color: $white;
}

.product-details-tab .tab-content {
  margin-top: 1rem !important;
}
.t-white {
  color: $white !important;
}
.offer-btn-image {
  width: 20px;
  margin: 0.5rem;
}
.country-im {
  height: 24px;
  width: 40px;
  margin-left: 1rem;
  display: inline-block;
}
.widget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subImages .count {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to top left,
    transparent 0,
    transparent 50%,
    var(--primary-color-dark) 0,
    var(--primary-color-light)
  );
  color: $white;
  padding: 4px 0 4px 4px;
  font-size: 10px;
  height: 35px;
  width: 30px;
  text-align: left;
  color: $dark1;
  font-weight: 600;
}

.product-desc-content {
  .table-responsive-sm {
    p {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
    }
  }
}
.page-desc {
  ul {
    li {
      position: relative;
      padding-left: 1.3rem;
      &:before {
        content: "•";
        position: absolute;
        display: block;
        top: 0;
        left: 0.2rem;
      }
    }
  }
}

.bannerContent {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  .btn {
    font-size: 14px;
    font-weight: 500;
    width: 90px;
    height: 32px;
    padding: 1rem 0.5rem;
  }
}
.bRightBox {
  padding: 20px 18px;
  background-color: $white;
  border-radius: 7px;
}

// responsiveness

@media only screen and (max-width: 1700px) {
  .bannerSection {
    grid-template-columns: 1fr 360px;
  }
  .slImg,
  .promotions img {
    height: 360px;
  }
}

@media only screen and (max-width: 1500px) {
  .bannerSection {
    grid-template-columns: 1fr 320px;
  }
  .slImg,
  .promotions img {
    height: 320px;
  }
}

@media only screen and (max-width: 1400px) {
  .bannerSection {
    grid-template-columns: 1fr;
  }
  .offer-box {
    display: none;
  }
  .slImg {
    height: auto;
  }
  .homeComp h3 {
    font-size: 18px;
    text-align: center;
  }
  .homeBoxContainer button {
    font-size: 10px;
    padding: 2px 5px;
    height: auto !important;
  }
  .saleChinaOff {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .shippingBox:first-child {
    margin-bottom: 0.75rem;
  }
  .shippingBox {
    margin-right: 0 !important;
  }
  .shippingBoxContainer {
    flex-direction: column;
  }
  .fs-13 {
    font-size: 13px;
  }
}
.banner-bottom-part {
  background-color: var(--primary-color-lighter);
  padding: 1.5rem 1rem;
}

.mainImg {
  width: 7rem;
}
.qr-code {
  max-width: 250px;
  min-width: 200px;
  margin: 0 auto !important;
}
.bankImg {
  width: 80px;
  height: 80px;
}
.bankDetails {
  h3 {
    font-size: 16px;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pay-img {
  width: 85%;
}
.pay-box {
  text-align: start;
}
.checkTable .card-body {
  padding: 0.4rem 0;
}

.qH {
  padding: 17px 0 !important;
}
.coupon-btn {
  font-size: 14px;
}
.totalWi {
  width: 10rem;
}

.btn-check {
  height: 36px;
  border: none;
  padding: 0 1rem;
  border-radius: 4px;
  color: $baseFontColor;
  padding: 0.8rem 0.8rem;
  border: none;
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
  }
}

.flxColCenter {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.sm-productAction {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: $white;
  padding: 5px 12px;
}

.sm-add {
  width: 50%;
  height: 35px;
  border: none;
  padding: 0 1rem;
  color: $baseFontColor;
  padding: 0.8rem 0.8rem;
  border: none;
  background: var(--primary-color-dark); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    var(--primary-color-light),
    var(--primary-color-dark)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
  }
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.sm-buy {
  width: 50%;
  height: 35px;
  border: none;
  padding: 0 1rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  color: $baseFontColor;
  padding: 0.8rem 0.8rem;
  border: none;
  background: var(--primary-color-dark); /* fallback for old browsers */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  cursor: pointer;
  &:hover,
  &:focus {
    color: $baseHoverFontColor;
  }
}
.dOprice {
  text-decoration: line-through;
  font-size: 13px;
  padding: 2px 0 0 0;
  margin: 0;
}
.dashboard .btn {
  min-width: 59px !important;
}

.mobileOAC {
  margin-top: 0.5rem;
  padding: 0.8rem;
  border-top: 1px solid lightgrey;
}
.dobt {
  width: 80% !important;
  padding: 0 !important;
  &:focus,
  &:hover {
    border: none;
    background: var(--primary-color-dark); /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to left,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
    color: $white;
  }
}
.order-disable {
  padding: 0 !important;
  border: 1px solid $baseColor;
  width: 80% !important;
  &:focus,
  &:hover {
    border: none;
    background: var(--primary-color-dark); /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
    color: $white;
  }
}
.invoice-disable {
  padding: 0.5rem 0.3rem;
  border: 1px solid $baseColor;
  width: 100% !important;
  &:focus,
  &:hover {
    border: none;
    background: var(--primary-color-dark); /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-dark));
    color: $white;
  }
}
.menu-vertical .menu-title {
  padding-bottom: 3px !important;
}
.subChildItem {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}
.swal-text {
  text-align: center !important;
}

.product-nav-thumbs span {
  box-shadow: none !important;
}

.minw-td {
  width: 140px;
}

.status-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.table td,
.table th {
  vertical-align: middle;
}

@media only screen and (min-width: 767px) {
  .fcatIm {
    height: 75px;
  
  }
  .nagadPay {
    text-align: end !important;
  }
  .product-image {
    height: 190px !important;
  }
}
@media only screen and (max-width: 375px) {
  .fot-mail {
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .card-title {
    font-size: 1.5rem;
    font-weight: 600 !important;
  }
  .minw-td {
    width: auto !important;
  }
  .bankImg {
    width: 100px;
    height: 90px;
  }
  .timerBox,
  .banner-bottom-part {
    display: none;
  }
  .bankDetailsText {
    font-size: 16px !important;
  }
  .btn-check {
    height: 30px;
    border: none;
    padding: 0 1rem;
    border-radius: 4px;
    color: $baseFontColor;
    padding: 0.8rem 0.8rem;
    border: none;
    background: var(--primary-color-dark); /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    cursor: pointer;
    &:hover,
    &:focus {
      color: $baseHoverFontColor;
    }
  }
  .checkout-btn {
    position: fixed;
    bottom: 45px;
    width: 80%;
    z-index: 9999;
    right: 10%;
    left: 10%;
    border-radius: 2rem;
  }
  .dotText {
    width: 68vw;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .qH {
    padding: 7px 0 !important;
  }
  .sh {
    height: auto !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }
  .table td,
  .table th {
    padding: 0.25rem;
  }
  .mainImg {
    width: 4rem;
  }
  .vImg {
    width: 3rem;
  }

  .hov-shadow {
    box-shadow: none !important;
  }
  .dealsSold {
    margin-bottom: 0.5rem !important;
  }
  .d-title {
    font-size: 14px;
    margin-bottom: 4px !important;
  }
  .cd-img {
    width: 35px !important;
    padding: 4px 0 0 0;
  }

  .product {
    border-radius: 1.2rem;
  }
  .product-title {
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .product_list_container {
    background-color: transparent;
  }

  .homeLogin-btn .dobt:disabled {
    opacity: 0.65;
  }

  .statusBoxes {
    grid-gap: 0.2rem;
  }

  .status-img {
    width: 20px;
  }
  .status-update,
  .status-title {
    font-size: 7px;
  }
  .status-box {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .c-card {
    border-radius: 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  .di-1 {
    padding-left: 5px;
    padding-right: 2px;
  }
  .di-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .di-3 {
    padding-left: 2px;
    padding-right: 5px;
  }
  .cd-img {
    width: 30px !important;
    padding: 4px 0 0 0;
  }
}
@media only screen and (max-width: 800px) {
  .homeBoxContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0.75rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .infoPageContentBlock {
    h2 {
      font-size: 18px;
    }
  }
}

.header-26 {
  position: fixed;
  top: 0;
  left: 0;
  .header-search {
    .header-search-wrapper {
      border-right: 0;
    }
    .img-icon {
      max-width: 40px;
    }
    .form-control {
      padding-left: 10px;
    }
  }
}

.fixed-sidebar {
  // position: absolute;
  // top: 64px;
  // left: 0;
  // z-index: 999;
  // padding: 0;
  // height: 100%;
  .side-nav {
    // position: absolute;
    // width: 225px;
    // top: 0;
    // left: 0;
    // height: 100%;
    // overflow-y: scroll;
    scrollbar-width: none;
  }
}
.side-nav::-webkit-scrollbar {
  width: 0;
}
.main-area {
  margin-left: 225px;
  margin-top: 64px;
  padding: 10px;
}
.quantify-bdr {
  height: 36px !important;
  background: $white;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: $baseColor;
}
.footer.footer-2 .footer-copyright {
  font-weight: 600 !important;
}
.megamenu {
  // display: block;
}

.cat-see {
  height: 22px;
  font-size: 12px;
}
// .menu-vertical .megamenu {
//   left: 35%;
// }

.addQ {
  padding: 17px 0 !important;
}
.page-wrapper {
  padding-top: 13.5rem;
}
.subChildCat {
  max-height: 150px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.subChildCat::-webkit-scrollbar {
  width: 1rem;
}
.shadow-0 {
  box-shadow: none;
}
.icon-none::after {
  display: none !important;
}
.icon-none:hover {
  font-weight: 600;
}
.title-sm {
  font-size: 18px;
}
.gap .gap-item {
  padding-right: 0;
}
.gap .gap-item:nth-child(6n) {
  padding-right: 10px;
}
.mb-10x {
  margin-bottom: 10px;
}
.object-cover {
  object-fit: cover;
}
.product-title a,
.product-title a:hover {
  color: $dark1;
}

.br-8 {
  border-radius: 8px;
}
.search-border {
  border: 1px solid var(--theme-color);
  border-radius: 11px;
}

.intro-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-intro-clearance .wishlist a {
  &:hover {
    color: $baseColor;
  }

  &:focus {
    color: $baseColor;
  }
}
#cd1 {
  &:hover {
    color: $baseColor;
  }

  &:focus {
    color: $baseColor;
  }
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.success-m-btn {
  display: inline-block;
  border-radius: 6px !important;
}

.cd-img {
  width: 80px;
}

.deleteItem {
  cursor: pointer;
  &:hover {
    color: #e21b1b;
  }
}
.logo img {
  height: 65px;
}

.footer-logo {
  margin: 0 !important;
  height: 65px;
}

.in-trust {
  font-size: 18px;
  font-weight: 700;
}
.in-address {
  font-size: 14px;
}
.in-Card {
  color: $dark1 !important;
  h4 {
    font-size: 18px;
    font-weight: 700;
  }
  h5 {
    font-size: 14px;
  }
}

.invoiceTable {
  color: $dark1 !important;
  tr {
    border: 1px solid $dark1;
    td {
      border: 1px solid $dark1;
    }
  }
}

// progress
$complete: var(--theme-color);
$active: var(--theme-color);
$mute: #a7a1a1;

.steps {
  margin-top: 3rem !important;
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: darken($mute, 33%);
  height: 4rem;

  > .step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 0.83rem;
    color: #6d6875;
    text-transform: uppercase;

    &:before {
      content: attr(data-step);
      display: block;
      margin: 0 auto;
      background: $white;
      border: 2px solid $mute;
      color: $mute;
      width: 2rem;
      height: 2rem;
      text-align: center;
      margin-bottom: -3.8rem;
      line-height: 1.9rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 700;
      font-size: 1rem;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      background: $mute;
      width: 100%;
      height: 0.125rem;
      top: 1rem;
      left: 50%;
    }
    &:last-child:after {
      display: none;
    }
    &.is-complete {
      color: #6d6875;

      &:before {
        content: "\2713";
        color: $complete;
        background: #fef0e2;
        border: 2px solid $complete;
      }
      &:after {
        background: $complete;
      }
    }
    &.is-active {
      font-size: 1.2rem;
      color: $active;

      &:before {
        color: $white;
        border: 2px solid $complete;
        background: $active;
        margin-bottom: -6.5rem;
      }
    }
  }
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: $white;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

// filter
.p1 {
  padding: 1rem;
}
.sortProductsContainer {
  justify-content: space-between;
  display: flex;
  align-items: center;

  select {
    height: 36px !important;
  }
  .filters {
    display: flex;
    .form-control {
      color: $dark1;
      border-color: var(--theme-color);
      font-weight: 600;
    }
    .btn {
      font-weight: 600;
    }
  }
  .priceFilter {
    display: flex;
    input {
      width: 100px;
    }
  }
}
.sug-btn {
  padding: 0.455rem 0.975rem;
  background-color: rgb(221, 221, 221);
  border-radius: 16px;
  margin: 0px 0.5rem;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: $baseHoverFontColor;
    background: -webkit-linear-gradient(
      to left,
      var(--primary-color-light),
      var(--primary-color-dark)
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, var(--primary-color-light), var(--primary-color-dark));
    transition: all 0.4s;
  }

  span {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: lowercase;
  }
}
.order-title {
  margin: 0 !important;
}
.summary {
  padding: 1.5rem;
  margin-top: 0;
}
.summary-shipping-estimate {
  a {
    margin-top: 0.6rem;
    display: inline-block;
    font-weight: 300;
    color: #999;
    font-size: 1.4rem;
    border-bottom: 0.1rem solid #999;
  }
  p {
    color: #666;
    font-size: 1.5rem;
  }
}

.oImg {
  width: 70px;
}

.summery-delTime {
  @extend .linear-primary-bg;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  margin: 0.5rem 0;
  border-radius: 5px;
  color: $baseFontColor;
}

.product-details-footer {
  border: none;
}

.pr-bot-action {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  .shareOpt {
    margin-left: 1rem;
  }
}

.cat-item {
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 0.2rem;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  white-space: break-spaces;
  &:hover {
    background-color: var(--primary-color-light);
  }
}

// payment success or fail page

.sub-fail-box {
  .suc-fail {
    max-width: 380px;
    margin: 10px auto;
    overflow: hidden;
  }

  .printer-top {
    z-index: 1;
    border: 6px solid var(--theme-hi-color);
    height: 6px;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    background: var(--theme-color);
  }

  .printer-bottom {
    z-index: 0;
    border: 6px solid var(--theme-hi-color);
    height: 6px;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    background: var(--theme-color);
  }

  .paper-container {
    position: relative;
    overflow: hidden;
    height: 467px;
    display: flex;
    justify-content: center;
  }
  .paper {
    background: #ffffff;

    position: absolute;
    z-index: 2;
    margin: 0 12px;
    margin-top: -12px;
    animation: print 5800ms cubic-bezier(0.68, -0.55, 0.265, 0.9) infinite;
    -moz-animation: print 5800ms cubic-bezier(0.68, -0.55, 0.265, 0.9) infinite;
  }
  .suc-papper {
    height: 460px;
  }
  .err-papper {
    height: 395px;
  }

  .main-contents {
    margin: 0 12px;
    padding: 24px;
  }

  // Paper Jagged Edge
  .jagged-edge {
    position: relative;
    height: 20px;
    width: 100%;
    margin-top: -1px;
  }

  .jagged-edge:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(
        45deg,
        transparent 33.333%,
        #ffffff 33.333%,
        #ffffff 66.667%,
        transparent 66.667%
      ),
      linear-gradient(-45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%);
    background-size: 16px 40px;
    background-position: 0 -20px;
  }

  .success-icon {
    text-align: center;
    font-size: 40px;
    background: #359d00;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin: 16px auto;
    color: #fff;
  }

  .failed-icon {
    text-align: center;
    font-size: 40px;
    background: #e21b1b;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin: 16px auto;
    color: #fff;
  }
  .success-title {
    font-size: 22px;
    text-align: center;
    color: #666;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .failed-title {
    font-size: 22px;
    text-align: center;
    color: #666;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .success-description {
    font-size: 15px;
    line-height: 21px;
    color: #999;
    text-align: center;
    margin-bottom: 24px;
  }

  .order-details {
    text-align: center;
    color: #333;
    font-weight: bold;
    .order-number-label {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .order-number {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      line-height: 48px;
      font-size: 48px;
      padding: 8px 0;
      margin-bottom: 24px;
    }
    .action {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      padding: 8px 0;
      margin-bottom: 24px;
    }
  }

  .order-footer {
    text-align: center;
    line-height: 18px;
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #999;
  }

  .error-notice {
    margin: 5px; /* Make sure to keep some distance from all sides */
  }

  .oaerror {
    width: 80%;
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    margin: 10px auto;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

  .danger {
    border-left-color: #d9534f; /* Left side border color */
    background-color: rgba(217, 83, 79, 0.1); /*Same color as the left border with reduced alpha to 0.1*/
  }

  .danger strong {
    color: #d9534f;
  }

  @keyframes print {
    0% {
      transform: translateY(-92%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @-webkit-keyframes print {
    0% {
      -webkit-transform: translateY(-92%);
    }
    100% {
      -webkit-transform: translateY(0%);
    }
  }

  @-moz-keyframes print {
    0% {
      -moz-transform: translateY(-92%);
    }
    100% {
      -moz-transform: translateY(0%);
    }
  }

  @-ms-keyframes print {
    0% {
      -ms-transform: translateY(-92%);
    }
    100% {
      -ms-transform: translateY(0%);
    }
  }
}

@media only screen and (max-width: 767px) {
  .steps {
    margin-top: 4rem !important;
    .step {
      font-size: 0.7rem;
      &:before {
        margin-bottom: -4.5rem;
      }

      &.is-active {
        font-size: 0.85rem;
        &:before {
          margin-bottom: -6rem;
        }
      }
    }
  }

  .form-tab {
    h1 {
      font-size: 2.2rem;
    }
  }
  .sortProductsContainer {
    flex-direction: column;
    align-items: normal;
    .filters {
      flex-direction: column;
      select {
        width: 100% !important;
      }
    }
    .priceFilter {
      flex-wrap: wrap;
      input {
        width: 100px;
      }
    }
  }
  .sugCat {
    display: none;
  }

  .due-Text {
    padding: 0 0.4rem;
    font-size: 16px;
    font-weight: 600;
  }
  .summery-delTime {
    flex-direction: column;
    align-items: center;
    padding: 2px 3px;
    p {
      line-height: 2.3rem;
    }
    .air {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .pr-bot-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    .shareOpt {
      margin-left: 0rem;
      margin-top: 0.7rem;
    }
  }
}


.download-button{
  width: 60px;
  height: 60px;
  border: none;
  background-color: #FE6F03;
  color: white;
  border-radius: 50%;
  font-size: x-small;
  font-weight: 400;
  padding: 5px;
  bottom: 50px;
  left: 10px;
}

@media only screen and (max-width: 350px) {
  .steps {
    .step {
      &.is-active {
        &:before {
          margin-bottom: -7.5rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 373px) {
  .steps {
    .step {
      &.is-active {
        &:before {
          margin-bottom: -6.8rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .product-image {
    height: 170px !important;
  }
}

@media only screen and (min-width: 1401px) {
  .intro-slide,
  .intro-section .col-lg-5cols > .banner.banner-overlay {
    min-height: 290px !important;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .intro-slide,
  .intro-section .col-lg-5cols > .banner.banner-overlay {
    min-height: 270px !important;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 992px) {
  .page-wrapper {
    padding-top: 8rem !important;
  }
}

@media (max-width: 991px) {
  .main-area {
    margin-left: 0;
  }
}
@media (max-width: 751px) {
  .main-area {
    margin-top: 124px;
  }
  .messenger-chat {
    display: none;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 400px) {
  .d-title {
    font-size: 13px;
    margin-bottom: 4px !important;
  }
  .cd-img {
    width: 25px !important;
  }
}
