/* Footers */
.footer {
  a {
    &:hover {
      color: var(--footer-hover-color);
    }

    &:focus {
      color: var(--footer-hover-color);
    }
  }
}

.bold {
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
.f-social-icon {
  margin-right: 0.4rem;
  img {
    width: 28px;
  }
}

.sisters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  width: 60%;

  a {
    padding: 10px;
    img {
      width: 100%;
      margin-right: 1rem;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      object-fit: contain;
      padding: 10px;
      height: 170px;
      @media only screen and (max-width: 900px) {
        height: 100px;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.575rem;
    gap: 0.575rem;
  }
}

.footer-dark.footer-2 {
  .widget-about-title {
    color: var(--footer-hover-color);
  }
}

/* Scroll Top Button */
/* Footer Dark */
/* Footer 2 */
.footer {
  width: 100%;
  font-weight: 300;
  font-size: 1.4rem;
  color: #000;
  background-color: #fff;

  a {
    color: inherit;

    &:hover {
      color: var(--footer-hover-color);
    }

    &:focus {
      color: var(--footer-hover-color);
    }
  }

  .widget {
    margin-bottom: 4rem;
  }

  .widget-title {
    color: #333333;
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: -0.01em;
    margin-top: 0;
    margin-bottom: 1.9rem;
  }
}

.footer-middle {
  padding: 6.7rem 0 2.2rem;
  border-top: 0.1rem solid #ebebeb;
}

.footer-logo {
  margin-bottom: 2rem;
  margin-top: -0.8rem;
}

.widget-about {
  p {
    max-width: 250px;

    &:last-of-type {
      margin-bottom: 3.2rem;
    }
  }
}

.container-fluid {
  .widget-about {
    p {
      max-width: 540px;
    }
  }
}

.widget-list {
  margin-bottom: 0;

  li {
    &:not(:last-child) {
      margin-bottom: 0.4rem;
    }
  }
}

.widget-newsletter {
  form {
    padding-top: 0.7rem;
  }

  .form-control {
    height: 46px;
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;
    background-color: #fff;
    border-right-width: 0;

    &:not(:focus) {
      border-color: #dadada;
    }
  }

  .btn {
    min-width: 46px;
    font-size: 1.5rem;
    padding: 1.1rem 1rem;

    i {
      margin: 0 !important;
    }
  }
}

.footer-bottom {
  .container {
    position: relative;
    display: flex;
    align-content: center;
    flex-direction: column;
    padding-top: 2.1rem;
    padding-bottom: 2.9rem;

    &:before {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 0;
      left: 10px;
      right: 10px;
      background-color: #ebebeb;
    }
  }

  .container-fluid {
    position: relative;
    display: flex;
    align-content: center;
    flex-direction: column;
    padding-top: 2.1rem;
    padding-bottom: 2.9rem;

    &:before {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 0;
      left: 10px;
      right: 10px;
      background-color: #ebebeb;
    }
  }

  .social-icons {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .social-icon {
    font-size: 1.3rem;
  }
}

.footer-copyright {
  margin-bottom: 1rem;
  text-align: center;
}

.footer-payments {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

#scroll-top {
  width: 3.5rem;
  height: 3.5rem;
  position: fixed;
  right: 50px;
  bottom: 130px;
  z-index: 999;
  color: var(--button-font-color);
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  visibility: hidden;
  opacity: 0;
  border-radius: 0;
  border: none;
  background-color: var(--button-bg-color);
  font-size: 2.5rem;
  transition: all 0.4s ease;
  transform: translateY(60px);
  -ms-transform: translateY(60px);

  &:hover {
    color: #777;
    background-color: var(--button-bg-color);
  }

  &:focus {
    color: #777;
    background-color: var(--button-bg-color);
  }
}

#scroll-top.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
}

.footer-dark {
  background-color: #333333;

  .footer-middle {
    border-top-width: 0;
  }

  .widget-title {
    color: #fff;
  }

  .social-icon {
    &:hover {
      color: #fff;
    }

    &:focus {
      color: #fff;
    }
  }

  .footer-bottom {
    color: #666666;

    .container {
      &:before {
        background-color: #525455;
      }
    }
  }
}

.footer-dark.footer-2 {
  .widget-about-info {
    a {
      &:not(:hover) {
        color: #fff;
      }
    }
  }

  .widget-about-title {
    color: #c96;
  }

  .footer-bottom {
    color: #999999;
  }
}

.footer-2 {
  .footer-middle {
    padding-top: 4.5rem;
    padding-bottom: 0.4rem;
  }

  .footer-bottom {
    .container {
      padding-top: 2.2rem;
      padding-bottom: 2.2rem;
      flex-direction: column-reverse;
    }
  }
}

.col-md-6 {
  .widget-about {
    p {
      max-width: none;
    }
  }
}

.col-lg-6 {
  .widget-about {
    p {
      max-width: none;
    }
  }
}

.widget-about-info {
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding-top: 0.7rem;

  .footer-payments {
    margin-top: 0.6rem;
  }
}

.widget-about-title {
  display: block;
  font-weight: 300;
  font-size: 1.3rem;
  color: #333333;
  letter-spacing: 0;
  margin-bottom: 0.4rem;
}

.footer-newsletter {
  color: #ebebeb;
  padding-top: 4.8rem;
  padding-bottom: 5.5rem;

  .heading {
    margin-bottom: 2.4rem;
  }

  .title {
    color: #fff;
    font-weight: 500;
  }

  .title-desc {
    color: inherit;
    font-size: 1.6rem;
    margin-top: 0.7rem;

    span {
      color: #fff;
      font-weight: 400;
    }
  }

  .form-control {
    height: 46px;
    padding: 1.25rem 2rem;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 300;
    color: #999999;
    background-color: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    transition: all 0.3s;
  }

  .form-control.form-control {
    &::placeholder {
      color: #999999;
    }
  }

  .btn {
    i {
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
}

.footer-menu {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  margin-left: auto;
  margin-right: auto;

  li {
    position: relative;

    + {
      li {
        margin-left: 2.1rem;

        &:before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 1.2rem;
          position: absolute;
          left: -1.1rem;
          top: 50%;
          margin-top: -0.6rem;
          background-color: #b5b5b5;
        }
      }
    }
  }

  a {
    box-shadow: 0 1px 0 #b5b5b5;
  }
}

@media screen and (min-width: 768px) {
  #scroll-top {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .footer-bottom {
    .container {
      flex-direction: row;
    }

    .container-fluid {
      flex-direction: row;
    }

    .social-icons {
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  .footer-copyright {
    margin-bottom: 0;
  }
  .footer-payments {
    margin-right: 0;
  }
  .footer-2 {
    .footer-bottom {
      .container-fluid {
        flex-direction: row;
      }
    }
  }
  .footer-menu {
    margin-bottom: 0;
    margin-left: 0.6rem;
    margin-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .footer-bottom {
    .container-fluid {
      &:before {
        left: 30px;
        right: 30px;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .footer-bottom {
    .container-fluid {
      &:before {
        left: 70px;
        right: 70px;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .footer {
    .widget-about {
      .social-icon {
        &:not(:last-child) {
          margin-right: 0.4rem;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .widget-about-info {
    .col-sm-6 {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
