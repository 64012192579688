/*--------------------- 6.Spacing + Helpers -------------------- */
/* Spacing */
.mt-0 {
  margin-top: 0rem !important;
}
.mb-0 {
  margin-bottom: 0rem !important;
}
.pt-0 {
  padding-top: 0rem !important;
}
.pb-0 {
  padding-bottom: 0rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.pt-2 {
  padding-top: 2rem !important;
}
.pb-2 {
  padding-bottom: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.pt-3 {
  padding-top: 3rem !important;
}
.pb-3 {
  padding-bottom: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.pt-4 {
  padding-top: 4rem !important;
}
.pb-4 {
  padding-bottom: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}
.pt-5 {
  padding-top: 5rem !important;
}
.pb-5 {
  padding-bottom: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.mb-6 {
  margin-bottom: 6rem !important;
}
.pt-6 {
  padding-top: 6rem !important;
}
.pb-6 {
  padding-bottom: 6rem !important;
}
.mt-7 {
  margin-top: 7rem !important;
}
.mb-7 {
  margin-bottom: 7rem !important;
}
.pt-7 {
  padding-top: 7rem !important;
}
.pb-7 {
  padding-bottom: 7rem !important;
}
.mt-8 {
  margin-top: 8rem !important;
}
.mb-8 {
  margin-bottom: 8rem !important;
}
.pt-8 {
  padding-top: 8rem !important;
}
.pb-8 {
  padding-bottom: 8rem !important;
}
.mt-9 {
  margin-top: 9rem !important;
}
.mb-9 {
  margin-bottom: 9rem !important;
}
.pt-9 {
  padding-top: 9rem !important;
}
.pb-9 {
  padding-bottom: 9rem !important;
}
.mt-10 {
  margin-top: 10rem !important;
}
.mb-10 {
  margin-bottom: 10rem !important;
}
.pt-10 {
  padding-top: 10rem !important;
}
.pb-10 {
  padding-bottom: 10rem !important;
}
.mt-11 {
  margin-top: 11rem !important;
}
.mb-11 {
  margin-bottom: 11rem !important;
}
.pt-11 {
  padding-top: 11rem !important;
}
.pb-11 {
  padding-bottom: 11rem !important;
}
.mt-12 {
  margin-top: 12rem !important;
}
.mb-12 {
  margin-bottom: 12rem !important;
}
.pt-12 {
  padding-top: 12rem !important;
}
.pb-12 {
  padding-bottom: 12rem !important;
}
.mt-13 {
  margin-top: 13rem !important;
}
.mb-13 {
  margin-bottom: 13rem !important;
}
.pt-13 {
  padding-top: 13rem !important;
}
.pb-13 {
  padding-bottom: 13rem !important;
}
.mt-14 {
  margin-top: 14rem !important;
}
.mb-14 {
  margin-bottom: 14rem !important;
}
.pt-14 {
  padding-top: 14rem !important;
}
.pb-14 {
  padding-bottom: 14rem !important;
}
.mt-15 {
  margin-top: 15rem !important;
}
.mb-15 {
  margin-bottom: 15rem !important;
}
.pt-15 {
  padding-top: 15rem !important;
}
.pb-15 {
  padding-bottom: 15rem !important;
}
@media screen and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0rem !important;
  }
  .mt-md-1 {
    margin-top: 1rem !important;
  }
  .mt-md-2 {
    margin-top: 2rem !important;
  }
  .mt-md-3 {
    margin-top: 3rem !important;
  }
  .mt-md-4 {
    margin-top: 4rem !important;
  }
  .mt-md-5 {
    margin-top: 5rem !important;
  }
  .mt-md-6 {
    margin-top: 6rem !important;
  }
  .mt-md-7 {
    margin-top: 7rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 9rem !important;
  }
  .mt-md-10 {
    margin-top: 10rem !important;
  }
  .mt-md-11 {
    margin-top: 11rem !important;
  }
  .mt-md-12 {
    margin-top: 12rem !important;
  }
  .mt-md-13 {
    margin-top: 13rem !important;
  }
  .mt-md-14 {
    margin-top: 14rem !important;
  }
  .mt-md-15 {
    margin-top: 15rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0rem !important;
  }
  .mb-md-1 {
    margin-bottom: 1rem !important;
  }
  .mb-md-2 {
    margin-bottom: 2rem !important;
  }
  .mb-md-3 {
    margin-bottom: 3rem !important;
  }
  .mb-md-4 {
    margin-bottom: 4rem !important;
  }
  .mb-md-5 {
    margin-bottom: 5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 6rem !important;
  }
  .mb-md-7 {
    margin-bottom: 7rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 9rem !important;
  }
  .mb-md-10 {
    margin-bottom: 10rem !important;
  }
  .mb-md-11 {
    margin-bottom: 11rem !important;
  }
  .mb-md-12 {
    margin-bottom: 12rem !important;
  }
  .mb-md-13 {
    margin-bottom: 13rem !important;
  }
  .mb-md-14 {
    margin-bottom: 14rem !important;
  }
  .mb-md-15 {
    margin-bottom: 15rem !important;
  }
  .pt-md-0 {
    padding-top: 0rem !important;
  }
  .pt-md-1 {
    padding-top: 1rem !important;
  }
  .pt-md-2 {
    padding-top: 2rem !important;
  }
  .pt-md-3 {
    padding-top: 3rem !important;
  }
  .pt-md-4 {
    padding-top: 4rem !important;
  }
  .pt-md-5 {
    padding-top: 5rem !important;
  }
  .pt-md-6 {
    padding-top: 6rem !important;
  }
  .pt-md-7 {
    padding-top: 7rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pt-md-9 {
    padding-top: 9rem !important;
  }
  .pt-md-10 {
    padding-top: 10rem !important;
  }
  .pt-md-11 {
    padding-top: 11rem !important;
  }
  .pt-md-12 {
    padding-top: 12rem !important;
  }
  .pt-md-13 {
    padding-top: 13rem !important;
  }
  .pt-md-14 {
    padding-top: 14rem !important;
  }
  .pt-md-15 {
    padding-top: 15rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0rem !important;
  }
  .pb-md-1 {
    padding-bottom: 1rem !important;
  }
  .pb-md-2 {
    padding-bottom: 2rem !important;
  }
  .pb-md-3 {
    padding-bottom: 3rem !important;
  }
  .pb-md-4 {
    padding-bottom: 4rem !important;
  }
  .pb-md-5 {
    padding-bottom: 5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 6rem !important;
  }
  .pb-md-7 {
    padding-bottom: 7rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .pb-md-9 {
    padding-bottom: 9rem !important;
  }
  .pb-md-10 {
    padding-bottom: 10rem !important;
  }
  .pb-md-11 {
    padding-bottom: 11rem !important;
  }
  .pb-md-12 {
    padding-bottom: 12rem !important;
  }
  .pb-md-13 {
    padding-bottom: 13rem !important;
  }
  .pb-md-14 {
    padding-bottom: 14rem !important;
  }
  .pb-md-15 {
    padding-bottom: 15rem !important;
  }
}
@media screen and (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0rem !important;
  }
  .mt-lg-1 {
    margin-top: 1rem !important;
  }
  .mt-lg-2 {
    margin-top: 2rem !important;
  }
  .mt-lg-3 {
    margin-top: 3rem !important;
  }
  .mt-lg-4 {
    margin-top: 4rem !important;
  }
  .mt-lg-5 {
    margin-top: 5rem !important;
  }
  .mt-lg-6 {
    margin-top: 6rem !important;
  }
  .mt-lg-7 {
    margin-top: 7rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 9rem !important;
  }
  .mt-lg-10 {
    margin-top: 10rem !important;
  }
  .mt-lg-11 {
    margin-top: 11rem !important;
  }
  .mt-lg-12 {
    margin-top: 12rem !important;
  }
  .mt-lg-13 {
    margin-top: 13rem !important;
  }
  .mt-lg-14 {
    margin-top: 14rem !important;
  }
  .mt-lg-15 {
    margin-top: 15rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 11rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 13rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 15rem !important;
  }
  .pt-lg-0 {
    padding-top: 0rem !important;
  }
  .pt-lg-1 {
    padding-top: 1rem !important;
  }
  .pt-lg-2 {
    padding-top: 2rem !important;
  }
  .pt-lg-3 {
    padding-top: 3rem !important;
  }
  .pt-lg-4 {
    padding-top: 4rem !important;
  }
  .pt-lg-5 {
    padding-top: 5rem !important;
  }
  .pt-lg-6 {
    padding-top: 6rem !important;
  }
  .pt-lg-7 {
    padding-top: 7rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pt-lg-9 {
    padding-top: 9rem !important;
  }
  .pt-lg-10 {
    padding-top: 10rem !important;
  }
  .pt-lg-11 {
    padding-top: 11rem !important;
  }
  .pt-lg-12 {
    padding-top: 12rem !important;
  }
  .pt-lg-13 {
    padding-top: 13rem !important;
  }
  .pt-lg-14 {
    padding-top: 14rem !important;
  }
  .pt-lg-15 {
    padding-top: 15rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 11rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 13rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 14rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 15rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0rem !important;
  }
  .mt-xl-1 {
    margin-top: 1rem !important;
  }
  .mt-xl-2 {
    margin-top: 2rem !important;
  }
  .mt-xl-3 {
    margin-top: 3rem !important;
  }
  .mt-xl-4 {
    margin-top: 4rem !important;
  }
  .mt-xl-5 {
    margin-top: 5rem !important;
  }
  .mt-xl-6 {
    margin-top: 6rem !important;
  }
  .mt-xl-7 {
    margin-top: 7rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 9rem !important;
  }
  .mt-xl-10 {
    margin-top: 10rem !important;
  }
  .mt-xl-11 {
    margin-top: 11rem !important;
  }
  .mt-xl-12 {
    margin-top: 12rem !important;
  }
  .mt-xl-13 {
    margin-top: 13rem !important;
  }
  .mt-xl-14 {
    margin-top: 14rem !important;
  }
  .mt-xl-15 {
    margin-top: 15rem !important;
  }
  .mb-xl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 11rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 13rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 15rem !important;
  }
}
@media screen and (min-width: 1600px) {
  .mt-xxl-0 {
    margin-top: 0rem !important;
  }
  .mt-xxl-1 {
    margin-top: 1rem !important;
  }
  .mt-xxl-2 {
    margin-top: 2rem !important;
  }
  .mt-xxl-3 {
    margin-top: 3rem !important;
  }
  .mt-xxl-4 {
    margin-top: 4rem !important;
  }
  .mt-xxl-5 {
    margin-top: 5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 6rem !important;
  }
  .mt-xxl-7 {
    margin-top: 7rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 9rem !important;
  }
  .mt-xxl-10 {
    margin-top: 10rem !important;
  }
  .mt-xxl-11 {
    margin-top: 11rem !important;
  }
  .mt-xxl-12 {
    margin-top: 12rem !important;
  }
  .mt-xxl-13 {
    margin-top: 13rem !important;
  }
  .mt-xxl-14 {
    margin-top: 14rem !important;
  }
  .mt-xxl-15 {
    margin-top: 15rem !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xxl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 11rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 13rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 15rem !important;
  }
}
