
.order_complete {

  padding: 60px 0;

  i {
    color: var(--theme-color);
    font-size: 80px;
    margin-bottom: 20px;
  }

  i.icon-close {
    color: red;
    font-size: 80px;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.6rem;
  }

  .heading_s1 {
    margin-bottom: 2rem;

    h3, {
      font-weight: 600;
      margin: 0;
      text-transform: capitalize;
      font-size: 2rem;
    }
  }
}


