.main-nav {
  margin-left: 2rem;
  display: none;
}

/* Menu Structure */

.menu {
  margin: 0;
  padding: 0;
  list-style: none;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  display: flex;
  align-items: center;

  li {
    position: relative;

    &:hover > {
      ul, .megamenu {
        display: block;
      }
    }

    &.show > {
      ul, .megamenu {
        display: block;
      }
    }
  }

  .megamenu-container {
    position: static;
  }

  ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 1002;

    ul {
      top: -1.6rem;
      left: 100%;
    }
  }

  .megamenu {
    display: none;
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    top: 100%;
    z-index: 1002;

    &.megamenu-sm {
      left: 0;
      right: auto;
      width: 456px;
    }

    &.megamenu-md {
      left: -10rem;
      right: auto;
      width: 694px;
    }

    > ul, div > ul {
      display: block;
      position: static;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      box-shadow: none;
      margin-top: 0;
      padding: 0;
      min-width: 0;
    }
  }

  ul, .megamenu {
    margin-top: 1px;
  }

  ul:before, .megamenu:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    height: 1px;
    left: 0;
    right: 0;
  }

  ul ul, .megamenu ul {
    margin-top: 0;
  }

  a:not(.btn) {
    display: block;
    position: relative;
    text-decoration: none;

    &:focus {
      outline: none !important;
    }
  }

  line-height: 1.5;

  li > a {
    color: #999999;
    font-weight: 300;
    font-size: 1.3rem;
    letter-spacing: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 3rem;
    padding-right: 3rem;

    span:not(.tip) {
      position: relative;
    }
  }

  > li {
    > a {
      color: #333;
      font-weight: 500;
      font-size: 1.4rem;
      letter-spacing: -.01em;
      padding: 3.95rem 3rem;
      text-transform: uppercase;
    }

    + li {
      margin-left: 0;
    }
  }

  &.sf-arrows {
    > li > .sf-with-ul {
      padding-right: 2rem;
    }

    .sf-with-ul {
      position: relative;

      &::after {
        font-family: 'molla';
        content: '\f110';
        position: absolute;
        right: 0;
        top: 50%;
        display: block;
        font-size: 1rem;
        line-height: 1;
        margin-top: -.5rem;
      }
    }

    ul .sf-with-ul {
      padding-right: 4rem;

      &:after {
        content: '\f112';
        right: 3rem;
      }
    }
  }

  .megamenu {
    background-color: #fff;
    box-shadow: 5px 10px 16px rgba(51, 51, 51, 0.05), -5px 10px 16px rgba(51, 51, 51, 0.05);
  }

  ul {
    background-color: #fff;
    box-shadow: 5px 10px 16px rgba(51, 51, 51, 0.05), -5px 10px 16px rgba(51, 51, 51, 0.05);
    min-width: 218px;
    padding: 1.6rem 0 2rem;
  }

  .megamenu {
    .banner > a {
      display: block;
      padding: 0;
      margin: 0;
    }

    li a {
      padding-right: 0;
      padding-left: 0;
    }

    .btn {
      min-width: 210px;
      text-transform: uppercase;
    }
  }

  .banner {
    margin: 0;
    max-width: 218px;
    float: right;
    height: 100%;
  }

  .menu-col {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 2.2rem;
    padding-bottom: 2rem;
  }

  .menu-title {
    color: #333;
    font-weight: 400;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-bottom: .6rem;
  }

  .megamenu-action {
    padding-bottom: 2rem;
  }

  ul + .menu-title {
    margin-top: 1.5rem;
  }

  .banner-content {
    padding-top: 0;

    &.banner-content-bottom {
      left: 2.4rem;
      bottom: 2.4rem;
    }

    &.banner-content-top {
      left: 2rem;
      top: 2.4rem;
      transform: translateY(0);
      -ms-transform: translateY(0);
    }
  }

  .banner-title {
    margin: 0;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 0;

    span {
      font-size: 2.4rem;
    }
  }

  .banner-content-top .banner-title span {
    line-height: 1.05;
    font-size: 3.1rem;
  }

  li {
    &:hover > a, &.show > a, &.active > a {
      color: #cc9966;
    }
  }
}

/* Menu Style */

/* Menu Hover Style */

/* Demos */

.demo-list {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin: 1.8rem -10px .4rem;
}

.demo-item {
  color: #999999;
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 10px;
  text-align: center;
  padding-bottom: 3rem;
  margin-bottom: auto;

  a {
    color: inherit;

    &:hover, &:focus {
      color: #cc9966;
    }

    &:hover .demo-bg, &:focus .demo-bg {
      box-shadow: 3px 10px 16px rgba(51, 51, 51, 0.05), -3px 10px 16px rgba(51, 51, 51, 0.05);
      background-position: center bottom;
      transition: background-position 2s linear, box-shadow .3s;
    }
  }
}

.demo-bg {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  background-color: #f4f4f4;
  border: .1rem solid #ebebeb;
  margin-bottom: 1.2rem;
  padding-top: 80.95%;
  background-position: center top;
  transition: background-position .6s linear, box-shadow .3s;
}

.demo-title {
  font-weight: 300;
  font-size: 1.3rem;
  letter-spacing: .01em;
}

/* Label - Tip */

.tip {
  color: #fff;
  display: block;
  position: absolute;
  left: 100%;
  bottom: 1.1rem;
  text-align: center;
  font-weight: 400;
  font-size: .8rem;
  line-height: 1;
  letter-spacing: 0;
  padding: .3rem .1rem .1rem;
  min-width: 23px;
  z-index: 1;
  border-radius: 0;
  margin-left: .5rem;
  text-transform: uppercase;
  background-color: #cc9966;

  &.tip-new {
    background-color: #a6c76c;
  }

  &.tip-hot {
    background-color: #ef837b;
  }
}

/* Header Bottom  - Menu position in header-bottom */

.header-bottom {
  .main-nav {
    margin-left: 0;
  }

  .menu {
    .megamenu.megamenu-md {
      left: 0;
    }

    > li {
      + li {
        margin-left: 1rem;
      }

      > {
        a {
          padding: 1.65rem 1rem;
        }

        .sf-with-ul {
          padding-right: 3rem;
        }

        a:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: .1rem;
          background-color: #cc9966;
          transform-origin: right center;
          transform: scale(0, 1);
          transition: transform .3s ease;
        }
      }
    }

    li {
      &:hover > a:before, &.show > a:before, &.active > a:before {
        transform-origin: left center;
        transform: scale(1, 1);
      }
    }

    &.sf-arrows > li > .sf-with-ul::after {
      right: 1rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .main-nav {
    display: block;
  }
}

.menu {
  li {
    &:hover {
      > a {
        color: #c96;
      }
    }
  }

  li.show {
    > a {
      color: #c96;
    }
  }

  li.active {
    > a {
      color: #c96;
    }
  }
}

.demo-item {
  a {
    &:hover {
      color: #c96;
    }

    &:focus {
      color: #c96;
    }
  }
}

.tip {
  background-color: #c96;
}

.header-bottom {
  .menu {
    > li {
      > a {
        &:before {
          background-color: #c96;
        }
      }
    }
  }
}

.category-dropdown {
  .dropdown-toggle {
    &:before {
      background-color: #c96;
    }

    &:hover {
      color: #fff;
      background-color: #c96;
    }

    &:focus {
      color: #fff;
      background-color: #c96;
    }
  }

  &:not(.is-on) {
    &:hover {
      .dropdown-toggle {
        background-color: #c96;
      }
    }
  }

  .dropdown-item {
    &:hover {
      color: #c96;
    }

    &:focus {
      color: #c96;
    }
  }
}

.category-dropdown.show {
  .dropdown-toggle {
    color: #fff;
    background-color: #c96;
  }
}


.menu {
  li {
    &:hover {
      > a {
        color: #c96;
      }
    }
  }

  li.show {
    > a {
      color: #c96;
    }
  }

  li.active {
    > a {
      color: #c96;
    }
  }
}

.demo-item {
  a {
    &:hover {
      color: #c96;
    }

    &:focus {
      color: #c96;
    }
  }
}

.tip {
  background-color: #c96;
}

.header-bottom {
  .menu {
    > li {
      > a {
        &:before {
          background-color: #c96;
        }
      }
    }
  }
}

.category-dropdown {
  .dropdown-toggle {
    &:before {
      background-color: #c96;
    }

    &:hover {
      color: #fff;
      background-color: #c96;
    }

    &:focus {
      color: #fff;
      background-color: #c96;
    }
  }

  &:not(.is-on) {
    &:hover {
      .dropdown-toggle {
        background-color: #c96;
      }
    }
  }

  .dropdown-item {
    &:hover {
      color: #c96;
    }

    &:focus {
      color: #c96;
    }
  }
}

.category-dropdown.show {
  .dropdown-toggle {
    color: #fff;
    background-color: #c96;
  }
}
