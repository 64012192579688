/* -------------------------------------------------
  (min-width: 1680px)
---------------------------------------------------*/
@media only screen and (min-width: 1680px) {
}

/* -------------------------------------------------
  (max-width: 1250px)
---------------------------------------------------*/
@media only screen and (max-width: 1250px) {
}

/* -------------------------------------------------
  (min-width: 991px) and (max-width: 1199px)
---------------------------------------------------*/
@media only screen and (min-width: 991px) and (max-width: 1199px) {
}

/* -------------------------------------------------
  (min-width: 767px) and (max-width: 1199px)
---------------------------------------------------*/
@media only screen and (min-width: 767px) and (max-width: 1199px) {
}

/* -------------------------------------------------
  (min-width: 767px) and (max-width: 991px)
---------------------------------------------------*/
@media only screen and (min-width: 767px) and (max-width: 991px) {
}

/* -------------------------------------------------
  max-width: 1199px
---------------------------------------------------*/
@media only screen and (max-width: 1199px) {
}

/* -------------------------------------------------
  min-width: 992px
---------------------------------------------------*/
@media only screen and (min-width: 992px) {
}

/* -------------------------------------------------
  max-width: 991px
---------------------------------------------------*/
@media only screen and (max-width: 991px) {
}

/* -------------------------------------------------
  min-width: 768px
---------------------------------------------------*/
@media only screen and (min-width: 768px) {
  .intro-slide,
  .intro-section .col-lg-5cols > .banner.banner-overlay {
    min-height: 508px;
  }
  .product-image {
    height: 235px;
  }

  .deal-section .title {
    margin-bottom: 0px;
    margin-top: 5px;
  }
}

/* -------------------------------------------------
  max-width: 767px
---------------------------------------------------*/
@media only screen and (max-width: 991px) {
  .intro-slide,
  .intro-section .col-lg-5cols > .banner.banner-overlay {
    min-height: 350px;
  }
  .product-image {
    height: 220px;
  }

  .title {
    font-size: 2rem;
  }
}

/* -------------------------------------------------
  max-width: 767px
---------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .intro-slide,
  .intro-section .col-lg-5cols > .banner.banner-overlay {
    min-height: 170px;
  }
  .product-image {
    height: 170px;
  }

  .title {
    font-size: 1.8rem;
  }

  .product-details-action .btn-cart {
    text-align: center;
  }

  .product-sidebar {
    background-color: transparent;
    padding: 0;
    overflow: hidden;
    margin-bottom: 15px;
  }
}

/* -------------------------------------------------
  max-width: 575px
---------------------------------------------------*/
@media only screen and (max-width: 575px) {
  .mobile_header .logo img {
    height: 54px;
  }

  .intro-slide,
  .intro-section .col-lg-5cols > .banner.banner-overlay {
    min-height: 220px;
  }
  .product-image {
    height: 200px;
  }
}

/* -------------------------------------------------
  max-width: 480px
---------------------------------------------------*/
@media only screen and (max-width: 480px) {
  .product-image {
    height: 190px;
  }
}

/* -------------------------------------------------
  max-width: 380px
---------------------------------------------------*/
@media only screen and (max-width: 380px) {
  .product-image {
    height: 180px;
  }
}
