/*--------------------- 4.Elements -------------------- */
/*--------------------- 4.1.Page Header -------------------- */
/* Page Header */
/*--------------------- 4.2.BreadCrumbs -------------------- */
/* Breadcrumb */
/*--------------------- 4.3.Titles -------------------- */
/* Titles */
/*--------------------- 4.4.Icon boxes -------------------- */
/* Icon boxes */
/* Icon Box Card */
/* Icon Boxes */
/*--------------------- 4.5.Cards -------------------- */
/*--------------------- 4.6.Tabs -------------------- */
/* Tabs */
/* Nav Pills - Line Tabs */
/* Vertical Tabs */
/* Tab content product - with carouse */
/*--------------------- 4.7.Buttons -------------------- */
/* Buttons */
/*--------------------- 4.8.Products -------------------- */
/* Product */
/* Product 2 */
/* Product 3 */
/* Product 4 */
/* Product 5 */
/* Product 6 */
/* Product 7 */
/* Product 8 */
/* Product 9 */
/* Product 10 */
/* Product 11 */
/* Product List */
/* Products Small */
/*--------------------- 4.9.Banners -------------------- */
/* Banners */
/*--------------------- 4.10.Banners Video -------------------- */
/* Banners Videos */
/*--------------------- 4.11.Banners Category -------------------- */
/* Banners Category */
/*--------------------- 4.12.Social Icons -------------------- */
/* Social Icons */
/*--------------------- 4.13.Testimonials -------------------- */
/* Testimonials */
/*--------------------- 4.14.Forms -------------------- */
/* Inputs */
/*--------------------- 4.15.Call to Actions -------------------- */
/* Call to Actions */
/*--------------------- 4.16.Tables -------------------- */
/* Tables */
/*--------------------- 4.17. Counters -------------------- */
/* Counters */
/*--------------------- 4.18.Pagination -------------------- */
/* Pagination */
/*--------------------- 4.19.Modals -------------------- */
/* Modals */
/*--------------------- 4.20.Alerts -------------------- */
/* Alerts */
/*--------------------- 4.21.Instagram Feeds -------------------- */
/* Instagram Feed */
/*--------------------- 4.21.Elements list -------------------- */
/* Lists */
.page-header {
  padding: 4.6rem 0 5rem;
  background-color: #ebebeb;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  h1 {
    color: #333333;
    font: normal 400 3.2rem/1.1 "Poppins", sans-serif;
    letter-spacing: -0.025em;
    margin-bottom: 0;

    span {
      display: block;
      color: #c96;
      font-size: 1.6rem;
      margin-top: 0.8rem;
    }
  }
}

.page-header.page-header-big {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  margin-bottom: 5rem;

  h1 {
    span {
      font-size: 1.6rem;
      margin-top: 0.4rem;
      letter-spacing: 0;
    }
  }
}

.breadcrumb-nav {
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid rgba(235, 235, 235, 0.55);

  .container {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  .container-fluid {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
}

.breadcrumb-with-filter {
  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .container-fluid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.breadcrumb-item {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: capitalize;

  i {
    font-size: 1.3rem;
    vertical-align: top;
    margin-top: -0.2rem;

    &::before {
      margin: 0;
    }
  }

  a {
    color: #666;

    &:hover {
      color: var(--theme-color);
    }

    &:focus {
      color: var(--theme-color);
    }
  }

  + {
    .breadcrumb-item {
      padding-left: 1rem;

      &:before {
        color: #999999;
        content: "\f112";
        font-family: "molla";
        padding-right: 0.7rem;
        font-size: 1.1rem;
        vertical-align: middle;
        margin-top: -0.1rem;
      }
    }
  }
}

.breadcrumb-item.active {
  color: #333333;
}

.title {
  font-size: 2.2rem;
  letter-spacing: -0.03em;
}

.title-lg {
  font-weight: 600;
  font-size: 2.6rem;
  letter-spacing: -0.01em;
}

.title-sm {
  font-size: 2rem;
  letter-spacing: -0.03em;
}

.heading {
  margin-bottom: 1.6rem;

  .title {
    margin-bottom: 0;
  }
}

.heading.heading-flex {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.heading.heading-center {
  text-align: center;

  .title {
    margin-bottom: 1.5rem;
  }

  .title-lg {
    margin-bottom: 2.4rem;
  }
}

.title-separator {
  font-weight: 300;
  color: #cccccc;
}

.text-white {
  .title-separator {
    color: #777;
  }
}

.title-desc {
  color: #777;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}

.heading-right {
  margin-top: 0.5rem;
}

.title-link {
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 1px 0 0 #c96;
  }

  &:focus {
    box-shadow: 0 1px 0 0 #c96;
  }

  i {
    font-size: 1.5rem;

    &:last-child {
      margin-left: 0.5rem;
    }
  }
}

.icon-box {
  color: #777;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
}

.icon-box.icon-box-sm {
  font-size: 1.4rem;
  line-height: 1.86;
}

.icon-box-title {
  color: #333333;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  margin-top: 0.2rem;
}

.icon-box-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #c96;
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 2rem;
}

.icon-box-content {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.icon-box-left {
  position: relative;
  padding-left: 70px;

  .icon-box-icon {
    position: absolute;
    left: 0;
    top: -1rem;
  }
}

.icon-box-circle {
  .icon-box-icon {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    color: #fff;
    background-color: #c96;
  }
}

.icon-box-circle.icon-box-left {
  padding-left: 100px;

  .icon-box-icon {
    left: 1rem;
  }
}

.icon-box-card {
  background-color: #fafafa;
  padding: 6.5rem 2rem 6rem;

  .icon-box-title {
    font-weight: 600;
  }

  .icon-box-icon {
    margin-bottom: 1.3rem;
  }
}

.icon-boxes-container {
  background-color: #f7f7f7;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  margin-bottom: 0;
}

.icon-boxes-container.bg-dark {
  .icon-box-icon {
    color: #666666;
  }

  .icon-box-title {
    color: #fff;
  }
}

.icon-box-side {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;

  p {
    margin-bottom: 0;
  }

  .icon-box-icon {
    display: inline-block;
    line-height: 0;
    min-width: 7rem;
    text-align: center;
    margin-bottom: 0;
  }

  .icon-box-title {
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: -0.01em;
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
}

.accordion {
  margin-bottom: 3rem;
}

.card {
  border-radius: 0;
  margin-bottom: 0;
  border: none;
  border-top: 0.1rem solid #ebebeb;

  &:last-child {
    border-bottom: 0.1rem solid #ebebeb;
  }
}

.card-header {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 700;
  line-height: 1.5;
  background-color: transparent;
  margin: 0;

  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.card-title {
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 0;

  a {
    display: block;
    padding: 1.7rem 4rem 1.7rem 1.2rem;
    position: relative;
    color: #c96;
    border: none;
    background-color: transparent;
    outline: none !important;

    i {
      min-width: 17px;
      margin-right: 2.4rem;
    }

    &:before {
      content: "\e802";
      display: inline-block;
      position: absolute;
      right: 1.2rem;
      top: 50%;
      color: #c96;
      font-family: "molla";
      line-height: 1;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transition: all 0.3s;
    }
  }

  a.collapsed {
    color: #333;

    &:hover {
      color: #c96;
    }

    &:focus {
      color: #c96;
    }

    &:before {
      content: "\f110";
      color: #8c8c8c;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
    }
  }
}

.card-body {
  padding: 0.4rem 4rem 1.8rem 1.2rem;
  border: none;
  border-radius: 0;
}

.accordion-icon {
  .card-body {
    padding-left: 5.5rem !important;
  }
}

.accordion-plus {
  .card-title {
    a {
      &:before {
        content: "\f28e";
      }
    }

    a.collapsed {
      &:before {
        content: "\f2c2";
      }
    }
  }
}

.accordion-rounded {
  .card {
    &:first-child {
      border-radius: 0.3rem 0.3rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 0.3rem 0.3rem;
    }
  }
}

.card.card-box {
  .card-title {
    a {
      padding-left: 2rem;

      &:before {
        right: 2rem;
      }

      i {
        margin-right: 1.4rem;
      }
    }
  }

  .card-body {
    padding: 1rem 2rem 2rem;
  }
}

.card.card-sm {
  .card-title {
    font-size: 1.6rem;
    letter-spacing: -0.01em;

    a {
      padding-top: 2.1rem;
      padding-bottom: 2.1rem;

      &:before {
        right: 2rem;
      }
    }
  }

  .card-body {
    padding: 1rem 2rem 2rem;
  }
}

.card.bg-light {
  background-color: #fff !important;
  border: 0.1rem solid #ebebeb;

  .card-title {
    a {
      background-color: #fff;
    }

    a.collapsed {
      background-color: #fafafa;
    }
  }
}

.card.bg-white {
  background-color: #fff !important;
  border: 0.1rem solid #ebebeb;

  .card-title {
    a {
      background-color: #fff;
    }
  }
}

.nav-link {
  &:focus {
    outline: none !important;
  }
}

.nav.nav-tabs {
  border-bottom-right-radius: 0.3rem;
  border: 0.1rem solid #d7d7d7;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;

  .nav-link {
    color: #777;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: -0.025em;
    padding: 0.85rem 2.2rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    text-align: center;
    transition: all 0.35s ease;

    &:hover {
      color: #c96;
      border-color: transparent;
    }

    &:focus {
      color: #c96;
      border-color: transparent;
    }
  }

  .nav-item {
    + {
      .nav-item {
        margin-left: 0.1rem;
      }
    }

    .nav-link.active {
      color: #c96;
      border-color: #d7d7d7;
      border-bottom-color: transparent;
    }
  }

  .nav-item.show {
    .nav-link {
      color: #c96;
      border-color: #d7d7d7;
      border-bottom-color: transparent;
    }
  }
}

.nav.nav-tabs.nav-tabs-bg {
  .nav-link {
    &:not(.active) {
      border-color: #ebebeb #ebebeb #d7d7d7 #ebebeb;
      background-color: #fafafa;
    }
  }
}

.nav.nav-tabs.justify-content-center {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.tab-content-border {
  border: 0.1rem solid #d7d7d7;
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  border-top-width: 0;
}

.justify-content-center {
  + {
    .tab-content-border {
      border-top-left-radius: 0.3rem;
    }
  }
}

.tab-pane {
  padding: 1.4rem 2.2rem;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.nav.nav-pills {
  .nav-link {
    color: #777;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: -0.01em;
    padding: 0.45rem 1rem;
    border-radius: 0;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-bottom: 0.1rem solid transparent;
    background-color: transparent;
    transition: all 0.35s ease;

    &:hover {
      color: $baseColor;
    }

    &:focus {
      color: $baseColor;
      outline: none !important;
    }
  }

  .nav-item {
    + {
      .nav-item {
        margin-left: 1rem;
      }
    }

    .nav-link.active {
      color: $baseColor;
      border-color: $baseColor;
      border-bottom-color: $baseColor;
    }
  }

  .nav-item.show {
    .nav-link {
      color: $baseColor;
      border-color: #d7d7d7;
      border-bottom-color: $baseColor;
    }
  }
}

.nav-pills {
  + {
    .tab-content {
      .tab-pane {
        padding: 1.4rem 0;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.tabs-vertical {
  display: flex;
  align-items: flex-start;
  align-items: stretch;

  .tab-content-border {
    border-top-width: 0.1rem;
    border-left-width: 0;
    border-bottom-left-radius: 0;
  }

  .nav {
    flex-shrink: 0;

    .nav-link {
      min-width: 100px;
    }
  }

  .nav.nav-tabs {
    border-bottom-width: 0;
    border-right-width: 0.1rem;

    .nav-link {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.3rem;
    }

    .nav-item {
      margin-bottom: 0;
      margin-right: -1px;

      + {
        .nav-item {
          margin-left: 0;
          margin-top: 1px;
        }
      }

      .nav-link.active {
        border-color: #d7d7d7;
        border-right-color: transparent;
      }
    }

    .nav-item.show {
      .nav-link {
        border-color: #d7d7d7;
        border-right-color: transparent;
      }
    }
  }

  .nav.nav-tabs.nav-tabs-bg {
    .nav-link {
      &:not(.active) {
        border-color: #ebebeb #d7d7d7 #ebebeb #ebebeb;
        background-color: #fafafa;
      }
    }
  }
}

.tab-content.tab-content-carousel {
  > .tab-pane {
    display: block;
    visibility: hidden;
    height: 0 !important;

    &:not(.active) {
      overflow: hidden !important;
    }
  }

  > .active {
    height: auto !important;
    visibility: visible;
  }
}

.nav.nav-border-anim {
  .nav-link {
    position: relative;
    border-bottom: none;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.1rem;
      background-color: #c96;
      transform-origin: right center;
      transform: scale(0, 1);
      transition: transform 0.3s ease;
    }
  }

  .nav-link.active {
    &:before {
      transform-origin: left center;
      transform: scale(1, 1);
    }
  }
}

.form-tab {
  .nav.nav-border-anim {
    .nav-link {
      &:before {
        height: 0.2rem;
      }
    }
  }
}

.nav.nav-border-anim.nav-pills-mobile {
  .nav-link {
    &:before {
      height: 0.2rem;
    }
  }
}

.btn-wrap {
  display: inline-block;
  margin-bottom: 2rem;

  > span {
    display: block;
    color: #ccc;
    margin-bottom: 0.3rem;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  border-radius: 0;
  white-space: normal;
  transition: all 0.3s;
  padding: 0.8rem 0.75rem;

  span {
    line-height: 1;
  }

  i {
    &:first-child {
      margin-right: 0.6rem;
    }

    &:last-child {
      margin-left: 0.6rem;
    }
  }

  &:focus {
    box-shadow: none;
    outline: none !important;

    .btn-text {
      display: none;
    }

    .btn-hover-text {
      display: inline-block;
    }
  }

  &:active {
    outline: none;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:hover {
    .btn-text {
      display: none;
    }

    .btn-hover-text {
      display: inline-block;
    }
  }
}

.btn.btn-minwidth-sm {
  min-width: 112px;
}

.btn.btn-minwidth {
  min-width: 170px;
}

.btn.btn-minwidth-lg {
  min-width: 218px;
}

.btn-rounded {
  border-radius: 0.2rem;
}

.btn-round {
  border-radius: 3rem;
}

.btn-lg {
  font-weight: 500;
  font-size: 1.5rem;
  min-width: 170px;
  padding: 1.1rem 2rem;

  i {
    &:first-child {
      margin-right: 1.4rem;
    }

    &:last-child {
      margin-left: 1.4rem;
    }
  }
}

.btn-more {
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
  min-width: 230px;
  text-transform: uppercase;
}

.btn-link {
  padding: 0.4rem 1rem;
  min-width: 0;
  color: var(--theme-color);
  text-decoration: none;
  border: none;
  border-bottom: 0.1rem solid transparent;
  letter-spacing: 0;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;

  &:hover {
    color: var(--theme-hover-color);
    text-decoration: none;
    border-color: var(--theme-hover-color);
  }

  &:focus {
    color: var(--theme-hover-color);
    text-decoration: none;
    border-color: var(--theme-hover-color);
  }

  .btn-link-dark {
    &:hover {
      color: var(--theme-hover-color);
      text-decoration: none;
      border-color: var(--theme-hover-color);
    }

    &:focus {
      color: var(--theme-hover-color);
      text-decoration: none;
      border-color: var(--theme-hover-color);
    }
  }

  i {
    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }
}

.btn-link.btn-link-dark {
  color: #333;
}

.btn-hover-text {
  display: none;
}

.btn-primary {
  color: $baseFontColor;
  background-color: $baseColor;
  border-color: $baseColor;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #000;
        border-color: #000;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #000;
      border-color: #000;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
  }
}

.btn-primary.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: none;
}

.show {
  > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-white.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-white-2.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  > .btn-primary-white.dropdown-toggle {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  > .btn-white-primary.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-outline.dropdown-toggle {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  > .btn-outline-primary.dropdown-toggle {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  > .btn-outline-primary-2.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-outline-light.dropdown-toggle {
    color: #c96;
    background-color: transparent;
    border-color: #fff;
    box-shadow: none;
  }

  > .btn-outline-dark.dropdown-toggle {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  > .btn-outline-dark-2.dropdown-toggle {
    color: #c96;
    background-color: #fafafa;
    border-color: #ebebeb;
    box-shadow: none;
  }

  > .btn-outline-dark-3.dropdown-toggle {
    color: #c96;
    background-color: transparent;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-outline-darker.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-outline-gray.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-outline-lightgray.dropdown-toggle {
    color: #c96;
    background-color: #f5f6f9;
    border-color: #ebebeb;
    box-shadow: none;
  }

  > .btn-outline-white.dropdown-toggle {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  > .btn-outline-white-2.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  > .btn-outline-white-3.dropdown-toggle {
    color: #333;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  > .btn-outline-white-4.dropdown-toggle {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  > .bg-image {
    .btn-outline-primary.dropdown-toggle {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
    }

    .btn-outline-dark.dropdown-toggle {
      color: #c96;
      background-color: #fff;
      border-color: #fff;
    }
  }
}

.btn-primary.disabled {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
}

.btn-secondary {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }
}

.btn-secondary.focus {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;
}

.btn-secondary.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-white {
  color: #333333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #333333;
    background-color: #fff;
    border-color: #fff;
  }
}

.btn-white.focus {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;
}

.btn-white.disabled {
  color: #333333;
  background-color: #fff;
  border-color: #fff;
}

.btn-white-2 {
  color: #333333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #000;
        border-color: #000;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #000;
      border-color: #000;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #333333;
    background-color: #fff;
    border-color: #fff;
  }
}

.btn-white-2.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: none;
}

.btn-white-2.disabled {
  color: #333333;
  background-color: #fff;
  border-color: #fff;
}

.btn-primary-white {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;

  &:hover {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  &:focus {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #c96;
        background-color: #fff;
        border-color: #fff;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #c96;
      background-color: #fff;
      border-color: #fff;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
  }
}

.btn-primary-white.focus {
  color: #c96;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

.btn-primary-white.disabled {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
}

.btn-white-primary {
  color: #c96;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
  }
}

.btn-white-primary.focus {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;
}

.btn-white-primary.disabled {
  color: #c96;
  background-color: #fff;
  border-color: #fff;
}

.btn-dark {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: #333;
    border-color: #333;
  }
}

.btn-dark.focus {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;
}

.btn-dark.disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-outline {
  color: #c96;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

  &:hover {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #c96;
        background-color: transparent;
        border-color: #ebebeb;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      }
    }

    &:not(.disabled).active {
      color: #c96;
      background-color: transparent;
      border-color: #ebebeb;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    }
  }

  &:disabled {
    color: #c96;
    background-color: transparent;
  }
}

.btn-outline.focus {
  color: #c96;
  background-color: transparent;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.btn-outline.disabled {
  color: #c96;
  background-color: transparent;
}

.btn-outline-primary {
  color: var(--theme-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--theme-color);
  box-shadow: none;

  &:hover {
    color: var(--theme-color);
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    color: var(--theme-color);
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: var(--theme-color);
        background-color: transparent;
        border-color: #ebebeb;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      }
    }

    &:not(.disabled).active {
      color: var(--theme-color);
      background-color: transparent;
      border-color: #ebebeb;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    }
  }

  &:disabled {
    color: var(--theme-color);
    background-color: transparent;
  }
}

.btn-outline-primary.focus {
  color: var(--theme-color);
  background-color: transparent;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.btn-outline-primary.disabled {
  color: var(--theme-color);
  background-color: transparent;
}

.btn-outline-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: var(--theme-color);
    background-color: transparent;
    border-color: #fff;
    box-shadow: none;
  }

  &:focus {
    color: var(--theme-color);
    background-color: transparent;
    border-color: #fff;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: var(--theme-color);
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: var(--theme-color);
      background-color: transparent;
      border-color: #fff;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: transparent;
  }
}

.btn-outline-light.focus {
  color: #c96;
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
}

.btn-outline-light.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-dark {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none;

  &:hover {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #c96;
        background-color: transparent;
        border-color: #ebebeb;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      }
    }

    &:not(.disabled).active {
      color: #c96;
      background-color: transparent;
      border-color: #ebebeb;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    }
  }

  &:disabled {
    color: #333333;
    background-color: transparent;
  }
}

.btn-outline-dark.focus {
  color: #c96;
  background-color: transparent;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.btn-outline-dark.disabled {
  color: #333333;
  background-color: transparent;
}

.btn-outline-dark-2 {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: none;

  &:hover {
    color: #c96;
    background-color: #fafafa;
    border-color: #ebebeb;
    box-shadow: none;
  }

  &:focus {
    color: #c96;
    background-color: #fafafa;
    border-color: #ebebeb;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #c96;
        background-color: #fafafa;
        border-color: #ebebeb;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #c96;
      background-color: #fafafa;
      border-color: #ebebeb;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #333333;
    background-color: transparent;
  }
}

.btn-outline-dark-2.focus {
  color: #c96;
  background-color: #fafafa;
  border-color: #ebebeb;
  box-shadow: none;
}

.btn-outline-dark-2.disabled {
  color: #333333;
  background-color: transparent;
}

.btn-outline-dark-3 {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none;

  &:hover {
    color: #c96;
    background-color: transparent;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #c96;
    background-color: transparent;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #c96;
        background-color: transparent;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #c96;
      background-color: transparent;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #333333;
    background-color: transparent;
  }
}

.btn-outline-dark-3.focus {
  color: #c96;
  background-color: transparent;
  border-color: #c96;
  box-shadow: none;
}

.btn-outline-dark-3.disabled {
  color: #333333;
  background-color: transparent;
}

.btn-outline-darker {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #333333;
    background-color: transparent;
  }
}

.btn-outline-darker.focus {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;
}

.btn-outline-darker.disabled {
  color: #333333;
  background-color: transparent;
}

.btn-outline-gray {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #959595;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #333333;
    background-color: transparent;
  }
}

.btn-outline-gray.focus {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;
}

.btn-outline-gray.disabled {
  color: #333333;
  background-color: transparent;
}

.btn-outline-lightgray {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: none;

  &:hover {
    color: #c96;
    background-color: #f5f6f9;
    border-color: #ebebeb;
    box-shadow: none;
  }

  &:focus {
    color: #c96;
    background-color: #f5f6f9;
    border-color: #ebebeb;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #c96;
        background-color: #f5f6f9;
        border-color: #ebebeb;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #c96;
      background-color: #f5f6f9;
      border-color: #ebebeb;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #333333;
    background-color: transparent;
  }
}

.btn-outline-lightgray.focus {
  color: #c96;
  background-color: #f5f6f9;
  border-color: #ebebeb;
  box-shadow: none;
}

.btn-outline-lightgray.disabled {
  color: #333333;
  background-color: transparent;
}

.btn-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05) !important;

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
  }

  &:focus {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
  }

  &:active {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
  }
}

.btn-outline-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: transparent;
  }
}

.btn-outline-white.focus {
  color: #fff;
  background-color: #c96;
  border-color: #c96;
  box-shadow: none;
}

.btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white-2 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #fff;
        background-color: #000;
        border-color: #000;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #fff;
      background-color: #000;
      border-color: #000;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: transparent;
  }
}

.btn-outline-white-2.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: none;
}

.btn-outline-white-2.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white-3 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: #333;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  &:focus {
    color: #333;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #333;
        background-color: #fff;
        border-color: #fff;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #333;
      background-color: #fff;
      border-color: #fff;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: transparent;
  }
}

.btn-outline-white-3.focus {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

.btn-outline-white-3.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white-4 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;

  &:hover {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  &:focus {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        color: #c96;
        background-color: #fff;
        border-color: #fff;
        box-shadow: none;
      }
    }

    &:not(.disabled).active {
      color: #c96;
      background-color: #fff;
      border-color: #fff;
      box-shadow: none;
    }
  }

  &:disabled {
    color: #fff;
    background-color: transparent;
  }
}

.btn-outline-white-4.focus {
  color: #c96;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

.btn-outline-white-4.disabled {
  color: #fff;
  background-color: transparent;
}

.bg-image {
  .btn-wrap {
    > span {
      color: #777;
    }
  }

  .btn-link-dark {
    color: #fff;

    &:hover {
      color: #c96;
      border-color: #c96;
    }

    &:focus {
      color: #c96;
      border-color: #c96;
    }
  }

  .btn-outline-primary {
    &:hover {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
    }

    &:focus {
      color: #fff;
      background-color: #c96;
      border-color: #c96;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          color: #fff;
          background-color: #c96;
          border-color: #c96;
        }
      }

      &:not(.disabled).active {
        color: #fff;
        background-color: #c96;
        border-color: #c96;
      }
    }
  }

  .btn-outline-primary.focus {
    color: #fff;
    background-color: #c96;
    border-color: #c96;
  }

  .btn-outline-dark {
    color: #fff;
    border-color: #fff;

    &:hover {
      color: #c96;
      background-color: #fff;
      border-color: #fff;
    }

    &:focus {
      color: #c96;
      background-color: #fff;
      border-color: #fff;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          color: #c96;
          background-color: #fff;
          border-color: #fff;
        }
      }

      &:not(.disabled).active {
        color: #c96;
        background-color: #fff;
        border-color: #fff;
      }
    }
  }

  .btn-outline-dark.focus {
    color: #c96;
    background-color: #fff;
    border-color: #fff;
  }
}

.product {
  position: relative;
  margin-bottom: 1.5rem;
  transition: box-shadow 0.35s ease;
  background-color: #fff;

  &:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);

    .product-image-hover {
      opacity: 1;
    }

    .product-countdown {
      opacity: 0;
      visibility: hidden;
    }

    .product-countdown-container {
      opacity: 0;
      visibility: hidden;
    }

    .product-action {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    .product-action-vertical {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  }

  &:focus {
    .product-action {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.owl-carousel {
  .product {
    margin-bottom: 0 !important;
  }

  .instagram-feed {
    margin-bottom: 0;
  }
}

.product-media {
  position: relative;
  display: block;
  background-color: #fff;
  margin-bottom: 0;
  overflow: hidden;

  > a {
    display: block;
  }
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 0.375rem;
  transition: all 0.2s ease;
  object-position: top;
  object-position: center center;
  transition: ease-in-out 200ms;
}

.product:hover .product-image {
  -webkit-transform: scale(0.97);
  transform: scale(0.97);
}

.product-countdown {
  position: absolute;
  left: 0.8rem;
  right: 0.8rem;
  bottom: 1.3rem;
  z-index: 6;
  transition: all 0.4s ease;
  visibility: visible;
  opacity: 1;

  .countdown-show4 {
    .countdown-section {
      width: calc(25% - 10px);
    }
  }

  .countdown-section {
    font-size: 1rem;
    line-height: 1;
    padding: 1.4rem 0 1.2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0.4rem;

    &:not(:last-child) {
      &:after {
        color: #fff;
        content: ":";
        display: inline-block;
        font-weight: 400;
        font-size: 20px;
        line-height: 1;
        position: absolute;
        left: 100%;
        margin-left: 3px;
        margin-top: -1px;
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
      }
    }
  }

  .countdown-period {
    display: block;
    font-weight: 400;
    color: #333333;
    text-transform: lowercase;
    width: 100%;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .countdown-amount {
    display: inline-block;
    color: #ef837b;
    font-weight: 500;
    font-size: 1.8rem;
    letter-spacing: -0.03em;
    margin-bottom: 0.6rem;
  }
}

.product-countdown.countdown-primary {
  .countdown-amount {
    color: #c96;
  }
}

.product-countdown-container {
  position: absolute;
  left: 1.4rem;
  right: 1.4rem;
  bottom: 1.3rem;
  z-index: 6;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.95);
  letter-spacing: -0.01em;
  transition: all 0.4s ease;
  visibility: visible;
  opacity: 1;

  .product-contdown-title {
    display: block;
    color: #333;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.2;
    margin-bottom: 0.3rem;
    text-align: center;
  }

  .product-countdown {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    z-index: 6;
    line-height: 1;
    opacity: 1;

    .countdown-amount {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}

.product-body {
  position: relative;
  padding: 1rem;
  transition: all 0.35s ease;
  background-color: #fff;

  .btn-wishlist {
    position: absolute;
    top: 3.4rem;
    right: 2rem;
    color: #ccc;
    padding: 0;
    margin: 0;
    background: none;

    &:before {
      margin-right: 0;
    }

    span {
      display: none;
    }

    &:hover {
      color: #c96;
      background: none;
    }

    &:focus {
      color: #c96;
      background: none;
    }
  }
}

.product-body.product-action-inner {
  padding-right: 4rem;
}

.product-title {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  color: #333333;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;

  a {
    color: $baseColor;
  }
}

.product-cat {
  color: #777;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.2;
  letter-spacing: -0.01em;
  margin-bottom: 0.3rem;

  a {
    color: inherit;

    &:hover {
      color: #666;
    }

    &:focus {
      color: #666;
    }
  }
}

.product-price {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.25;
  color: #000;
  margin-top: 0;

  .sold_item_text {
    color: #5f5f5f;
    font-size: 12px;
    float: right;
  }

  .price_span {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.new-price {
  color: #ef837b;
  margin-right: 0.8rem;
}

.old-price {
  color: #cccccc;
  text-decoration: line-through;
}

.out-price {
  color: #cccccc;
}

.out-text {
  display: block;
  width: 100%;
  font-size: 1.3rem;
  margin-top: 0.2rem;
}

.product-label {
  position: absolute;
  z-index: 1;
  top: 2rem;
  left: 2rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: -0.01em;
  padding: 0.5rem 0.9rem;
  min-width: 45px;
  text-align: center;
  color: #333333;
  background-color: #fff;

  + {
    .product-label {
      top: 5rem;
    }
  }
}

.product-label.label-sale {
  color: $baseFontColor;
  background-color: $baseColor;
}

.product-label.label-new {
  color: $baseFontColor;
  background-color: $baseColor;
}

.product-label.label-top {
  color: #fff;
  background-color: #7dd2ea;
}

.product-label.label-out {
  color: #fff;
  background-color: #ccc;
}

.product-label.label-primary {
  color: #fff;
  background-color: #c96;
}

.product-label.label-secondary {
  color: #fff;
  background-color: #000;
}

.product-label-text {
  color: #c96;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-top: -0.1rem;
}

.label-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.6rem;
  height: 4.6rem;
  min-width: 0;
  border-radius: 50%;
  line-height: 1;

  + {
    .label-circle {
      top: 5.6rem;
    }
  }
}

.label-circle.label-out {
  font-size: 1.1rem;
}

.ratings-container {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-bottom: 1.7rem;
  line-height: 1;
  white-space: nowrap;
}

.ratings {
  position: relative;
  color: #cccccc;
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 1;
  font-family: "molla";

  &:before {
    content: "\f318""\f318""\f318""\f318""\f318";
  }
}

.ratings-val {
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 1;
  font-family: "molla";
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #fcb941;

  &:before {
    content: "\f318""\f318""\f318""\f318""\f318";
  }
}

.ratings-primary {
  .ratings-val {
    color: #c96;
  }
}

.ratings-text {
  color: #cccccc;
  letter-spacing: -0.01em;
  margin-left: 0.8rem;

  a {
    color: inherit;

    &:hover {
      color: #c96;
    }

    &:focus {
      color: #c96;
    }
  }
}

.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 10;
  transition: all 0.35s ease;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
}

.action-icon-top {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  .btn-product {
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: column;

    span {
      margin-top: 0.9rem;
    }
  }

  &:before {
    margin-right: 0;
  }
}

.btn-product {
  color: #c96;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  line-height: 1;
  transition: all 0.35s ease;
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  flex-grow: 1;
  flex-basis: 0;

  + {
    .btn-product {
      border-left: 0.1rem dotted #e5e5e5;
    }
  }

  &:before {
    font-family: "molla";
    margin-right: 0.9rem;
  }

  span {
    color: #666666;
    font-weight: 400;
    font-size: 1.3rem;
    letter-spacing: -0.01em;
    transition: all 0.35s ease;
  }

  &:hover {
    outline: none !important;

    span {
      color: #c96;
      box-shadow: 0 1px 0 0 #c96;
    }
  }

  &:focus {
    outline: none !important;

    span {
      color: #c96;
      box-shadow: 0 1px 0 0 #c96;
    }
  }
}

.product-action-dark {
  background-color: #333333;

  .btn-product {
    &:not(:hover) {
      &:not(:focus) {
        span {
          color: #fff;
        }
      }
    }

    + {
      .btn-product {
        border-left-color: #4b4b4b;
      }
    }
  }
}

.product-action-vertical {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2rem;
  top: 2rem;
  background-color: transparent;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease;
  transform: translateX(-15px);
}

.btn-product-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  padding: 0;
  font-size: 1.4rem;
  line-height: 1;
  transition: all 0.35s ease;
  padding: 0;
  border-radius: 50%;
  color: var(--theme-color);
  background-color: #fff;

  &:before {
    font-family: "molla", sans-serif;
  }

  span {
    display: none;
  }

  &:hover {
    color: #fff;
    background-color: var(--theme-color);
  }

  &:focus {
    color: #fff;
    background-color: var(--theme-color);
  }

  + {
    .btn-product-icon {
      margin-top: 1rem;
    }
  }
}

.btn-cart {
  &:before {
    content: "\e812";
  }
}

.btn-wishlist {
  font-size: 1.6rem;

  &:before {
    content: "\f234";
  }
}

.btn-quickview {
  &:before {
    content: "\f145";
  }
}

.btn-compare {
  &:before {
    content: "\f2d0";
  }
}

.btn-expandable {
  position: relative;

  span {
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    align-items: center;
    display: inline-flex;
    font-weight: 300;
    font-size: 1.1rem;
    white-space: nowrap;
    letter-spacing: 0;
    min-width: 100px;
    padding-left: 1.8rem;
    padding-right: 0.4rem;
    color: #fff;
    background-color: var(--theme-color);
    transition: all 0.35s ease;
    opacity: 0;
    visibility: hidden;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transform: translateX(15px);
    z-index: -1;
  }

  &:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  }

  &:focus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.product-size {
  display: flex;
  align-items: center;
  padding-top: 0.2rem;
  margin-bottom: 1.6rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 2.1rem;
    height: 2.1rem;
    font-weight: 400;
    font-size: 1.2rem;
    padding-top: 0.1rem;
    line-height: 1;
    color: #666666;
    background-color: #f7f8fa;
    border-radius: 0.2rem;
    transition: all 0.35s ease;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #fff;
      background-color: #333;
    }

    &:focus {
      color: #fff;
      background-color: #333;
    }
  }

  a.active {
    color: #fff;
    background-color: #333;
  }

  a.disabled {
    color: #cccccc;
    pointer-events: none;
  }
}

.product-nav {
  display: block;
  align-items: center;
}

.product-nav-dots {
  margin-bottom: 0.5rem;

  a {
    display: block;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border: 0.2rem solid #fff;
    margin-right: 0.5rem;
    transition: box-shadow 0.35s ease;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
      box-shadow: 0 0 0 0.1rem #cccccc;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem #cccccc;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  span {
    display: block;
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    border: 0.2rem solid #fff;
    margin-right: 0.5rem;
    transition: box-shadow 0.35s ease;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
      box-shadow: 0 0 0 0.1rem #cccccc;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem #cccccc;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a.active {
    box-shadow: 0 0 0 0.1rem #cccccc;
  }

  span.active {
    box-shadow: 0 0 0 0.1rem #cccccc;
  }
}

.product-nav-thumbs {
  padding-top: 0.2rem;
  margin-bottom: 1.6rem;

  a {
    display: inline;
    align-items: center;
    min-width: 3.2rem;
    border-radius: 0.2rem;
    border: 1px solid #eee;
    margin-right: 0.8rem;
    margin-bottom: 0.4rem;
    transition: box-shadow 0.35s ease;
    float: left;
    padding: 2px 2px;
    position: relative;

    &:hover {
      box-shadow: 0 0 0 0.1rem $baseColor;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem $baseColor;
    }

    &:last-child {
      margin-right: 0;
    }

    &.disabled {
      cursor: no-drop;
      opacity: 0.4;
      border: 1px dashed #0c0c0c;
    }

    &.noImage {
      background-color: #eee;
      padding: 6px 10px;
      margin-bottom: 10px;
      color: #444;
    }
  }

  a.active {
    box-shadow: 0 0 0 0.1rem $baseColor;

    &::after {
      content: "";
      background-color: transparent;
      color: #e96d28;
      font-weight: bold;
      position: absolute;
      z-index: 99999;
      width: 10px;
      height: 10px;
      top: -3px;
      right: -8px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e96d28;
      transform: rotate(45deg);
    }
  }

  span {
    box-shadow: 0 0 0 0.1rem $baseColor;
  }

  a.isSelect {
    box-shadow: 0 0 0 0.2rem $selectAttributeBorderColor;
  }

  img {
    border-radius: 0.2rem;
  }
}

.product-disabled {
  pointer-events: none;

  .btn-product {
    pointer-events: none;
    color: #fff;
    background-color: #ccc !important;
  }

  .btn-product-icon {
    pointer-events: none;
    color: #fff;
    background-color: #ccc !important;
  }
}

.product.text-center {
  .product-price {
    justify-content: center;
  }

  .ratings-container {
    justify-content: center;
  }

  .product-nav {
    justify-content: center;
  }

  .product-size {
    justify-content: center;
  }
}

.product.product-2 {
  .product-body {
    padding-bottom: 0.4rem;
  }

  .product-action {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .btn-product {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    + {
      .btn-product {
        border-left-style: solid;
      }
    }
  }

  .ratings-container {
    &:last-child {
      margin-bottom: 1.6rem;
    }
  }

  .product-action-vertical {
    top: 3rem;
  }

  .product-nav-dots {
    margin-bottom: 2rem;
  }
}

.product-footer {
  display: none;
  padding-top: 1.3rem;
}

.product.product-3 {
  position: relative;
  overflow: hidden;
  background-color: #fff;

  .product-action {
    position: absolute;
    bottom: 100%;
    border-bottom: 0.1rem solid #ebebeb;
    background-color: #fff;
    transform: translateY(0);
    transition-duration: 0.25s;
  }

  .btn-product {
    padding-top: 1.05rem;
    padding-bottom: 1.05rem;

    &:not(:hover) {
      &:not(:focus) {
        span {
          color: #333;
        }
      }
    }
  }

  .product-price {
    margin-bottom: 0;
  }

  .product-nav {
    margin-bottom: 0;
  }

  .product-body {
    background-color: #fff;
  }

  .product-footer {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    transform: translateY(100%);
    visibility: hidden;
    transition: all 0.35s ease;
    padding: 0 2rem 1rem;
  }

  &:hover {
    box-shadow: none;
  }
}

.product.product-4 {
  .product-body {
    padding-bottom: 2rem;
    z-index: 2;

    div {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .product-action {
    background-color: transparent;
  }

  .product-action-vertical {
    top: 3rem;
    transform: translate(0, 10px);
  }

  .btn-product {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    color: #fff;
    background-color: #c96;

    span {
      color: inherit;
      transition-duration: 0s;
    }

    &:hover {
      color: #fff;
      background-color: #000;

      span {
        box-shadow: none;
      }
    }

    &:focus {
      color: #fff;
      background-color: #000;

      span {
        box-shadow: none;
      }
    }
  }

  .product-title {
    margin-bottom: 1rem;
  }

  .product-nav {
    margin-bottom: 0;
  }

  .product-footer {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    padding-top: 0.3rem;
    padding-bottom: 2.5rem;
    transition: all 0.35s ease;
    background-color: #fff;
    z-index: 1;
  }

  .ratings-container {
    margin-bottom: 0;
  }

  &:hover {
    .product-action-vertical {
      transform: translate(0, 0);
    }

    .product-footer {
      opacity: 1;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    }
  }
}

.product-4-carousel {
  .owl-stage {
    padding-bottom: 4rem;
  }
}

.product.product-5 {
  .product-body {
    padding-bottom: 1.6rem;
    background-color: #fff;
    transition-duration: 0s;
    z-index: 2;

    div {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .product-action {
    background-color: transparent;
  }

  .product-action-vertical {
    transform: translate(10px, 0);
  }

  .btn-product {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    color: #c96;
    background-color: #333333;

    span {
      color: #fff;
      transition-duration: 0s;
    }

    &:hover {
      color: #fff;
      background-color: #c96;

      span {
        box-shadow: none;
      }
    }

    &:focus {
      color: #fff;
      background-color: #c96;

      span {
        box-shadow: none;
      }
    }
  }

  .product-title {
    margin-bottom: 1rem;
  }

  .product-footer {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    padding-top: 0;
    padding-bottom: 2rem;
    transition: all 0.35s ease;
    background-color: #fff;
    z-index: 1;
  }

  .ratings-container {
    margin-bottom: 0;

    + {
      .product-nav {
        margin-top: 1.7rem;
      }
    }
  }

  .product-nav {
    margin-bottom: 0;
  }

  &:hover {
    .product-footer {
      opacity: 1;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    }
  }
}

.product-5-carousel {
  .owl-stage {
    padding-bottom: 7rem;
  }
}

.product.product-6 {
  overflow: hidden;

  .product-body {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-right: 2rem;
    padding-bottom: 0;
    transform: translateY(100%);
  }

  .product-action {
    position: static;
    left: auto;
    right: auto;
    bottom: auto;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0.1rem solid #ebebeb;
    margin-top: 2.4rem;
  }

  .btn-product {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    + {
      .btn-product {
        border-left-style: solid;
      }
    }
  }

  .product-cat {
    margin-bottom: 0.5rem;
  }

  .product-title {
    margin-bottom: 1rem;
  }

  &:hover {
    box-shadow: none;

    .product-body {
      transform: translateY(0);
    }
  }
}

.product.product-7 {
  .btn-product {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    color: #c96;
    background-color: #fff;
    text-transform: uppercase;
    border-bottom: 0.1rem solid #ebebeb;

    span {
      color: #c96;
    }

    &:before {
      display: inline-block;
      margin-top: -0.1rem;
    }

    &:hover {
      color: #fff;
      background-color: #c96;
      border-bottom-color: #c96;

      span {
        color: #fff;
        box-shadow: none;
      }
    }

    &:focus {
      color: #fff;
      background-color: #c96;
      border-bottom-color: #c96;

      span {
        color: #fff;
        box-shadow: none;
      }
    }
  }

  .product-nav-thumbs {
    margin-bottom: 1rem;
  }
}

.product.product-8 {
  margin-bottom: 2rem;

  .product-media {
    border: 0.1rem solid #ebebeb;
  }

  .product-body {
    padding: 1.9rem 0.5rem 4rem;
  }

  .product-cat {
    color: #ccc;
    margin-bottom: 0.5rem;
  }

  .product-action {
    background-color: transparent;
  }

  .product-action-vertical {
    transform: translate(0, 10px);
  }

  .new-price {
    color: #c96;
  }

  .btn-product {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    color: #fff;
    background-color: #333;
    text-transform: uppercase;

    &:before {
      color: #c96;
      margin-top: -2px;
      margin-right: 0.6rem;
    }

    span {
      font-size: 1.4rem;
      color: inherit;
      transition-duration: 0s;
    }

    &:hover {
      color: #fff;
      background-color: #c96;

      &:before {
        color: #fff;
      }

      span {
        box-shadow: none;
      }
    }

    &:focus {
      color: #fff;
      background-color: #c96;

      &:before {
        color: #fff;
      }

      span {
        box-shadow: none;
      }
    }
  }

  .product-title {
    margin-bottom: 1rem;
  }

  .product-price {
    margin-bottom: 0.9rem;
  }

  .product-nav {
    padding-top: 0.4rem;
    margin-bottom: 1.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ratings-container {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2.2rem;
    margin-bottom: 0;
    transition: all 0.35s ease;
  }

  &:hover {
    .product-action-vertical {
      transform: translate(0, 0);
    }

    .ratings-container {
      opacity: 1;
    }
  }
}

.product.product-9 {
  .product-price {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #333;
  }

  .old-price {
    text-decoration: none;
  }

  .label-sale {
    background-color: #c96;
    text-transform: uppercase;
  }

  .product-body {
    padding: 1rem 2rem;
    transition: all 0.3s;
  }

  .product-action {
    bottom: auto;
    background-color: #fff;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1rem;
      height: 0.1rem;
      background-color: #ebebeb;
    }
  }

  .product-detail {
    position: absolute;
    visibility: hidden;
    padding-top: 1rem;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s;
  }

  .btn-product {
    padding-top: 1.2rem;
    padding-bottom: 2.2rem;
  }

  &:hover {
    margin-bottom: -9rem !important;
    box-shadow: none;

    .product-body {
      transform: translateY(-100%);
    }

    .product-action {
      transform: translateY(-100%);
    }

    .product-intro {
      padding-bottom: 9rem;
    }

    .product-detail {
      bottom: 1rem;
      opacity: 1;
      visibility: visible;
    }
  }

  .product-nav-dots {
    a {
      width: 4rem;
      height: 5rem;
      border-radius: 0.5rem;
    }

    span {
      width: 4rem;
      height: 5rem;
      border-radius: 0.5rem;
    }
  }
}

.product.product-10 {
  &:hover {
    .product-body {
      transform: translateY(-56px);
    }

    .product-action {
      transform: translateY(22%);
    }
  }

  &:focus {
    .product-body {
      transform: translateY(-56px);
    }

    .product-action {
      transform: translateY(22%);
    }
  }

  .btn-expandable {
    span {
      background-color: #fcb941;
    }
  }

  .product-media {
    background-color: #fff;
    padding: 2rem;
  }

  .product-image-hover {
    padding: 2rem;
  }

  .product-body {
    .btn-wishlist {
      width: fit-content;
      left: 50%;
      right: 0;
      top: 4.5rem;
      transform: translateX(-50%);

      span {
        margin-left: 0.5rem;
        display: flex;
      }

      &:hover {
        color: #666;

        &::before {
          content: "\f233";
        }
      }

      &:focus {
        color: #666;

        &::before {
          content: "\f233";
        }
      }
    }

    padding-bottom: 5rem;
  }

  .btn-product {
    &:hover {
      span {
        color: #c96;
        box-shadow: 0 1px 0 0 #fcb941;
      }
    }

    &:focus {
      span {
        color: #c96;
        box-shadow: 0 1px 0 0 #fcb941;
      }
    }
  }

  .product-action {
    display: flex;
    justify-content: center;
  }

  .btn-cart {
    padding: 0.5rem 3.5rem;
    border: 0.2rem solid #fcb941;
    border-radius: 0.5rem;
    color: #fcb941;

    span {
      text-transform: uppercase;
    }

    &::before {
      content: "";
    }

    &:hover {
      color: #222;
      background-color: #fcb941;
    }

    &:focus {
      color: #222;
      background-color: #fcb941;
    }
  }

  .btn-quickview {
    color: #fcb941;

    &:hover {
      color: #fff;
      background-color: #fcb941;
    }

    &:focus {
      color: #fff;
      background-color: #fcb941;
    }
  }

  .product-title {
    a {
      font-size: 1.3rem;
      font-weight: 400;
      font-family: "Poppins";
      letter-spacing: 0;
      color: #333;

      &:hover {
        color: #fcb941;
      }

      &:focus {
        color: #fcb941;
      }
    }
  }

  .product-price {
    margin-top: 0.8rem;
    font-size: 1.3rem;
    font-weight: 700;
    font-family: "Poppins";
    letter-spacing: 0;
    color: #333333;
  }

  .new-price {
    font-size: 1.3rem;
    font-weight: 700;
    font-family: "Poppins";
    letter-spacing: 0;
  }

  .old-price {
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "Poppins";
    letter-spacing: 0;
    text-decoration: none;
  }

  .product-label {
    left: 3rem;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 700;
    font-family: "Poppins";
    letter-spacing: -0.01em;
  }

  .product-label.label-deal {
    color: #fff;
    background-color: #ef837b;
    padding: 0.5rem 2rem;
    left: 0;
    box-shadow: 0 5px 10px rgba(34, 34, 34, 0.2);
  }
}

.product.product-11 {
  a {
    &:hover {
      color: #cc9966;
    }

    &:focus {
      color: #cc9966;
    }
  }

  .btn-product-icon {
    color: #333;
    border: 0.1rem solid #ebebeb;

    &:hover {
      color: #fff;
      background-color: #cc9966;
      border-color: #cc9966;
    }

    &:focus {
      color: #fff;
      background-color: #cc9966;
      border-color: #cc9966;
    }
  }

  .product-price {
    color: #333;
  }

  .old-price {
    text-decoration: none;
  }

  .product-body {
    padding-bottom: 7rem;
  }

  .product-action {
    bottom: 2rem;
  }

  .btn-product {
    text-transform: uppercase;
    color: #333;
    border: 0.1rem solid #ebebeb;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    span {
      font-size: 1.4rem;
    }

    &:hover {
      color: #fff;
      border-color: #cc9966;
      background-color: #cc9966;

      span {
        color: #fff;
        box-shadow: none;
      }
    }

    &:focus {
      color: #fff;
      border-color: #cc9966;
      background-color: #cc9966;

      span {
        color: #fff;
        box-shadow: none;
      }
    }
  }

  &:hover {
    z-index: 10;
  }

  &:focus {
    z-index: 10;
  }
}

.product.product-list {
  box-shadow: none;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #ebebeb;
  margin-bottom: 2rem;

  .product-media {
    height: 100%;

    > a {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product-body {
    padding: 1.6rem 0 0;

    .btn-wishlist {
      top: 3.1rem;
    }
  }

  .product-list-action {
    padding: 3.4rem 0 0;
  }

  .product-content {
    color: #777;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.43;
    padding-top: 1rem;
    margin-bottom: 3.4rem;

    p {
      margin-bottom: 0;
    }
  }

  .product-action {
    position: static;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    margin-bottom: 1.2rem;
  }

  .ratings-container {
    margin-bottom: 2.6rem;
  }

  .btn-product {
    color: #777;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.4;
    flex: 1 1 50%;
    max-width: 50%;

    &:before {
      margin-right: 0.5rem;
    }

    span {
      color: inherit;
      font-size: 1.2rem;
      transition: box-shadow 0.35s ease, color 0s ease;
    }

    &:hover {
      color: #c96;

      span {
        color: inherit;
      }
    }

    &:focus {
      color: #c96;

      span {
        color: inherit;
      }
    }
  }

  .btn-product.disabled {
    pointer-events: none;
  }

  .btn-product.btn-cart {
    color: #c96;
    border: 0.1rem solid #c96;
    flex: 1 1 100%;
    max-width: 100%;
    text-transform: uppercase;
    line-height: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: all 0.35s ease;

    span {
      font-size: 1.3rem;
    }

    &:hover {
      color: #fff;
      background-color: #c96;

      span {
        box-shadow: none;
      }
    }

    &:focus {
      color: #fff;
      background-color: #c96;

      span {
        box-shadow: none;
      }
    }
  }

  .btn-product.btn-cart.disabled {
    color: #ccc;
    border-color: #ebebeb;
    background-color: #fafafa;

    &:before {
      display: none;
    }
  }

  .product-nav {
    margin-bottom: 0;
  }
}

.product.product-sm {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  &:hover {
    box-shadow: none;
  }

  .product-media {
    flex: 0 0 80px;
    max-width: 80px;
  }

  .product-body {
    flex: 1 1 auto;
    padding: 1rem 0 0 2rem;
  }

  .product-cat {
    color: #cccccc;
    font-weight: 400;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
  }

  .product-title {
    font-size: 1.4rem;
    margin-bottom: 0.2rem;
  }

  .product-price {
    font-size: 1.6rem;
    margin-bottom: 0;

    .old-price {
      text-decoration: none;
    }
  }
}

.banners-group {
  margin-bottom: 1.9rem;
}

.banner {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  background-color: #ccc;

  > a {
    position: relative;
    display: block;
    outline: none !important;
  }

  img {
    display: block;
    max-width: none;
    width: 100%;
    height: auto;
  }

  &:hover {
    .banner-content-overlay {
      padding-bottom: 3.7rem;
      background-color: white;

      .banner-link {
        opacity: 1;
        transform: translateY(0);
        -ms-transform: translateY(0);
      }
    }

    .banner-content-static {
      background-color: white;

      .banner-subtitle {
        opacity: 0;
      }

      .banner-link {
        opacity: 1;
        transform: translateY(0);
        -ms-transform: translateY(0);
      }
    }
  }
}

.banner.banner-menu {
  img {
    height: 100%;
  }
}

.banner-content {
  display: inline-block;
  position: absolute;
  padding-top: 0.4rem;
  left: 2rem;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);

  a {
    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;
    }
  }
}

.banner-big {
  .banner-content.banner-content-center {
    max-width: none;
    left: 0;
    right: 0;
    text-align: center;
  }

  color: #cccccc;

  .banner-content {
    max-width: 470px;
    left: 3rem;
  }

  .banner-subtitle {
    font-size: 1.8rem;
    margin-bottom: 0.6rem;
  }

  .banner-title {
    font-size: 3rem;
    line-height: 1.15;
    margin-bottom: 1rem;

    + {
      .btn {
        margin-top: 1rem;
      }
    }
  }

  p {
    &:last-of-type {
      margin-bottom: 2.3rem;
    }
  }
}

.banner-content-center.banner-content {
  max-width: none;
  left: 0;
  right: 0;
  text-align: center;
}

.banner-title {
  color: #333333;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }
}

.banner-title.text-white {
  a {
    color: #fff;
  }
}

.banner-subtitle {
  color: #777;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: 1rem;

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }
}

.banner-subtitle.text-white {
  a {
    color: #fff;
  }
}

.banner-link {
  font-weight: 400;
  display: inline-block;
}

.banner-display {
  > a {
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      background-color: rgba(51, 51, 51, 0.4);
      transition: all 0.35s ease;
    }
  }

  &:hover {
    > a {
      &:before {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .banner-content {
    padding-top: 0;
  }

  .banner-title {
    font-weight: 600;
    font-size: 2rem;
  }
}

.banner-display.banner-link-anim {
  .banner-title {
    margin-bottom: 0;
  }

  &:hover {
    .banner-content {
      padding-bottom: 4.5rem;
    }
  }
}

.btn.banner-link {
  font-size: 1.4rem;
  line-height: 1;
  padding: 0.8rem 1.4rem;
  min-width: 0;
  text-transform: uppercase;
  text-decoration: none !important;

  i {
    margin-right: 0;
    margin-left: 0;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0.9rem;
    }
  }
}

.banner-overlay {
  > a {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(51, 51, 51, 0.25);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;
    }
  }

  &:hover {
    > a {
      &:after {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &:focus {
    > a {
      &:after {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.banner-overlay.banner-overlay-light {
  > a {
    &:after {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.video-banner {
  p {
    max-width: 500px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.video-banner.text-center {
  p {
    margin-left: auto;
    margin-right: auto;
  }
}

.video-banner-bg {
  padding-top: 8.5rem;
  padding-bottom: 7.5rem;
}

.video-banner-title {
  letter-spacing: -0.025em;
  margin-bottom: 3.6rem;

  > span {
    display: block;
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: -0.03em;
    margin-bottom: 1.6rem;
  }
}

.video-fullheight {
  display: flex;
  align-items: center;
}

.btn-video {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 5.4rem;
  height: 5.4rem;
  font-size: 1.6rem;
  line-height: 1;
  color: #c96;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1.5rem rgba(255, 255, 255, 0.2);
  transition: all 0.35s ease;

  i {
    margin-right: -0.2rem;
  }

  &:hover {
    color: #fff;
    background-color: #000;
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.2);
  }

  &:focus {
    color: #fff;
    background-color: #000;
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0.2);
  }
}

.video-banner-poster {
  .video-banner-title {
    font-weight: 400;
    margin-bottom: 1.6rem;

    span {
      font-weight: 300;
      font-size: 1.6rem;
    }
  }
}

.video-poster {
  position: relative;

  img {
    border-radius: 0.4rem;
  }
}

.video-poster-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-poster-title {
  font-weight: 400;
  margin-bottom: 3rem;
  letter-spacing: -0.025em;
}

.video-banner-box {
  padding: 3rem;

  .video-poster {
    img {
      border-radius: 0;
    }
  }
}

.video-box-content {
  padding-left: 0;
  max-width: 460px;
  font-size: 1.5rem;
  color: #777;

  .video-banner-title {
    font-weight: 400;
    margin-bottom: 1.2rem;

    span {
      font-weight: 300;
      font-size: 1.8rem;
      margin-bottom: 0.6rem;
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.banner-cat {
  .banner-title {
    margin-bottom: 0.1rem;
  }

  .banner-subtitle {
    margin-bottom: 1.6rem;
  }
}

.banner-content-overlay {
  overflow: hidden;
  padding: 1.8rem 2rem 0.7rem;
  background-color: rgba(255, 255, 255, 0.9);
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  transform: translateY(0);
  -ms-transform: translateY(0);
  transition: all 0.3s ease;

  .banner-subtitle {
    margin-bottom: 0.9rem;
  }

  .banner-link {
    opacity: 0;
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    transform: translateY(30px);
    -ms-transform: translateY(30px);
  }
}

.banner-content-static {
  overflow: hidden;
  display: block;
  width: 100%;
  margin-top: 0;
  padding: 1.2rem 2rem;
  background-color: white;
  position: static;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  transform: translateY(0);
  -ms-transform: translateY(0);
  transition: all 0.3s ease;

  .banner-subtitle {
    transition: all 0.25s ease;
    margin-bottom: 0.9rem;
  }

  .banner-link {
    opacity: 0;
    position: absolute;
    bottom: 19px;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    transform: translateY(30px);
    -ms-transform: translateY(30px);
  }
}

.banner-content.banner-content-top {
  top: 105px;
}

.banner-content.banner-content-bottom {
  top: auto;
  bottom: 60px;
  transform: translateY(0);
  -ms-transform: translateY(0);
}

.banner-content.banner-content-right {
  left: auto;
  right: 30px;
}

.banner-link-anim {
  .banner-content {
    transition: all 0.3s ease;
  }

  .banner-subtitle {
    margin-bottom: 0;
  }

  .banner-link {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 130px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: all 0.3s ease;
    transform: translateY(-20px);
    -ms-transform: translateY(-20px);
  }

  .banner-content-center {
    .banner-link {
      left: 50%;
      transform: translateY(-20px) translateX(-50%);
      -ms-transform: translateY(-20px) translateX(-50%);
    }
  }

  &:hover {
    .banner-content {
      padding-bottom: 4rem;
    }

    .banner-link {
      opacity: 1;
      transform: translateY(0);
      -ms-transform: translateY(0);
    }

    .banner-content-center {
      .banner-link {
        transform: translateY(0) translateX(-50%);
        -ms-transform: translateY(0) translateX(-50%);
      }
    }
  }
}

.banner-badge {
  .banner-link {
    font-size: 1.6rem;
    display: inline-block;
    background-color: #fff;
    padding: 1.2rem 2rem;
    text-align: center;
    min-width: 170px;
    border-radius: 0.2rem;
    position: absolute;
    top: auto;
    bottom: 3.2rem;
    right: auto;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    -ms-transform: translateX(-50%) translateY(0);
    overflow: hidden;

    &:hover {
      text-decoration: none;

      .banner-title {
        opacity: 0;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
      }

      .banner-subtitle {
        opacity: 0;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
      }

      .banner-link-text {
        opacity: 1;
        background-color: #c96;
        transform: translateY(0);
        -webkit-transform: translateY(0);
      }
    }

    &:focus {
      text-decoration: none;

      .banner-title {
        opacity: 0;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
      }

      .banner-subtitle {
        opacity: 0;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
      }

      .banner-link-text {
        opacity: 1;
        background-color: #c96;
        transform: translateY(0);
        -webkit-transform: translateY(0);
      }
    }
  }

  .banner-title {
    transition: all 0.3s ease;
  }

  .banner-subtitle {
    transition: all 0.3s ease;
    margin-bottom: 0;
  }

  .banner-link-text {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    transition: all 0.3s ease;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
  }
}

.social-icons {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  width: 4rem;
  height: 4rem;
  color: #777;
  margin: 0;
  background-color: transparent;
  border: 0.1rem solid #e1e2e6;
  border-radius: 50%;
  text-decoration: none;
  opacity: 1;
  transition: all 0.35s ease;

  &:not(:last-child) {
    margin-right: 2px;
  }

  &:hover {
    color: var(--theme-hover-color);
    border-color: var(--theme-hover-color);
    background-color: transparent;
  }

  &:focus {
    color: var(--theme-hover-color);
    border-color: var(--theme-hover-color);
    background-color: transparent;
  }
}

.social-icon.social-facebook {
  color: #8f79ed;
}

.social-icon.social-twitter {
  color: #79c8ed;
}

.social-icon.social-instagram {
  color: #dd6d9a;
}

.social-icon.social-youtube {
  color: #e66262;
}

.social-icon.social-pinterest {
  color: #e66262;
}

.social-icon.social-linkedin {
  color: #3399cc;
}

.social-icons-color {
  line-height: 1;

  .social-icon {
    width: auto;
    height: auto;
    border: none;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.social-icons-simple {
  line-height: 1;

  .social-icon {
    width: auto;
    height: auto;
    border: none;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.social-label {
  margin-bottom: 0;
  margin-right: 2rem;
}

.social-icons-sm {
  .social-icon {
    font-size: 1.2rem;
    width: 3rem;
    height: 3rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .social-label {
    margin-right: 1rem;
  }
}

.social-icons-colored {
  .social-icon.social-facebook {
    color: #fff;
    border-color: #8f79ed;
    background-color: #8f79ed;
  }

  .social-icon.social-twitter {
    color: #fff;
    border-color: #79c8ed;
    background-color: #79c8ed;
  }

  .social-icon.social-instagram {
    color: #fff;
    border-color: #dd6d9a;
    background-color: #dd6d9a;
  }

  .social-icon.social-youtube {
    color: #fff;
    border-color: #e66262;
    background-color: #e66262;
  }

  .social-icon.social-pinterest {
    color: #fff;
    border-color: #e66262;
    background-color: #e66262;
  }

  .social-icon.social-linkedin {
    color: #fff;
    border-color: #3399cc;
    background-color: #3399cc;
  }

  .social-icon {
    &:hover {
      background-color: #fff !important;
      border-color: #d7d7d7 !important;
    }

    &:focus {
      background-color: #fff !important;
      border-color: #d7d7d7 !important;
    }

    &:hover.social-facebook {
      color: #8f79ed;
    }

    &:focus.social-facebook {
      color: #8f79ed;
    }

    &:hover.social-twitter {
      color: #79c8ed;
    }

    &:focus.social-twitter {
      color: #79c8ed;
    }

    &:hover.social-instagram {
      color: #dd6d9a;
    }

    &:focus.social-instagram {
      color: #dd6d9a;
    }

    &:hover.social-youtube {
      color: #e66262;
    }

    &:focus.social-youtube {
      color: #e66262;
    }

    &:hover.social-pinterest {
      color: #e66262;
    }

    &:focus.social-pinterest {
      color: #e66262;
    }

    &:hover.social-linkedin {
      color: #3399cc;
    }

    &:focus.social-linkedin {
      color: #3399cc;
    }
  }
}

.testimonial-icon {
  &:before {
    font-family: "molla";
    content: "\f10d";
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 2.6rem;
    line-height: 1;
    color: #c96;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.testimonial {
  img {
    display: inline-block !important;
    max-width: 70px;
    border-radius: 50%;
    margin-top: 1rem;
    margin-bottom: 2.1rem;
  }
}

.owl-testimonials.owl-theme {
  .owl-nav {
    [class*="owl-"] {
      top: 65px;
    }
  }
}

.owl-testimonials-photo.owl-theme {
  .owl-nav {
    [class*="owl-"] {
      top: 110px;
    }
  }
}

.form-group {
  margin-bottom: 2rem;

  .form-control {
    margin-bottom: 0;
  }

  .select-custom {
    margin-bottom: 0;
  }
}

.form-control {
  height: 35px;
  padding: 0.85rem 2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 300;
  color: #777;
  background-color: #fafafa;
  border: 1px solid #ebebeb;
  border-radius: 0;
  margin-bottom: 2rem;
  transition: all 0.3s;
  box-shadow: none;

  &:focus {
    color: #777;
    background-color: #fff;
    border-color: #c96;
    box-shadow: none;
    outline: none !important;
  }

  &::placeholder {
    color: #777;
  }

  + {
    .form-text {
      margin-top: -0.5rem;
      margin-bottom: 0.6rem;
    }
  }
}

select.form-control {
  &:not([size]) {
    &:not([multiple]) {
      height: 40px;
    }
  }
}

.form-control-white {
  border-color: #fff;
}

textarea.form-control {
  min-height: 130px;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
}

.form-control-rounded {
  border-radius: 0.2rem;
}

.input-group-round {
  .form-control {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  > .input-group-append {
    > .btn {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
}

label {
  color: #666666;
  font-weight: 300;
  font-size: 1.4rem;
}

.form-text {
  color: #999999;
  line-height: 1.5;
  margin-top: 0;
}

small.form-text {
  font-size: 85%;
}

.required-field {
  > label {
    &::after {
      content: "*";
      color: #e02b27;
      font-size: 1.2rem;
      margin: 0 0 0 0.45rem;
    }
  }
}

.required {
  color: #e02b27;
  font-size: 1.2rem;

  + {
    .form-footer {
      margin-top: 0.8rem;
    }
  }
}

.form-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0.6rem;
}

.select-custom {
  position: relative;
  margin-bottom: 1rem;

  select {
    -webkit-appearance: none;
    appearance: none;
  }

  &::after {
    font-family: "molla";
    content: "\f110";
    position: absolute;
    display: inline-block;
    right: 1.9rem;
    top: 50%;
    font-size: 1.5rem;
    transform: translateY(-51%);
    color: #333333;
  }

  .form-control {
    padding-right: 4rem;
  }

  .form-control-sm {
    padding-right: 4rem;
  }
}

.form-group-custom-control {
  margin-top: 2rem;

  .custom-control {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  + {
    .form-group-custom-control {
      margin-top: -0.5rem;
    }
  }

  .custom-control-label {
    text-transform: initial;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.custom-control {
  position: relative;
  padding-left: 2.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.custom-control-label {
  position: static;
  margin-bottom: 0;
  margin-top: 0.1rem;
  font-size: 1.4rem;
  padding-left: 2.6rem;
  margin-left: -2.6rem;

  &::before {
    left: 0;
    top: 0.6rem;
    width: 1.6rem;
    height: 1.6rem;
    background-color: #f9f9f9;
    border: 1px solid #dadada;
    box-shadow: none !important;
  }

  &::after {
    left: 0;
    top: 0.6rem;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.custom-control-input {
  &:focus {
    &:not(:checked) {
      ~ {
        .custom-control-label {
          &::before {
            border-color: #dadada;
          }
        }
      }
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    &::before {
      border-radius: 0;
    }

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-image: none;
      font-size: 1rem;
      font-family: "molla";
      content: "\ec2d";
      line-height: 1;
      text-align: center;
      opacity: 0;
      margin-top: 0.1rem;
      visibility: hidden;
      transition: opacity 0.01s;
    }
  }

  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            color: #fff;
            border-color: #333;
            background-color: #333;
          }

          &::after {
            background-image: none;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    &:indeterminate {
      ~ {
        .custom-control-label {
          &::before {
            background-color: #333;
          }
        }
      }
    }

    &:disabled {
      &:checked {
        ~ {
          .custom-control-label {
            &::before {
              background-color: rgba(51, 51, 51, 0.5);
            }
          }
        }
      }

      &:indeterminate {
        ~ {
          .custom-control-label {
            &::before {
              background-color: rgba(51, 51, 51, 0.5);
            }
          }
        }
      }
    }
  }
}

.custom-control.custom-radio {
  .custom-control-label {
    &::before {
      top: 0.4rem;
      background-color: #f9f9f9;
      border-color: #dadada;
      border-radius: 50%;
    }

    &::after {
      border-radius: 50%;
      width: 0.6rem;
      height: 0.6rem;
      top: 0.9rem;
      left: 0.5rem;
    }
  }

  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            border-color: #c96;
            background-color: #fff;
          }

          &::after {
            background-image: none !important;
            z-index: 2;
            background-color: #c96;
          }
        }
      }
    }
  }
}

.form-control-tooltip {
  position: relative;
  padding-right: 4rem;

  .input-tooltip {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    font-size: 2.4rem;
    color: #777;
    transform: translateY(-50%);
    transition: all 0.3s;

    &:hover {
      color: #000;
    }

    &:focus {
      color: #000;
    }
  }
}

.cta {
  .input-group {
    margin-bottom: 0;
  }

  .form-control {
    height: 46px;
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;
    background-color: #fff;

    &:not(:focus) {
      &:not(.form-control-white) {
        border-color: #dadada;
      }
    }
  }

  .btn {
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;

    &:not(.btn-block) {
      min-width: 170px;
    }
  }
}

.cta.bg-image {
  background-color: #f5f5f5;
}

.cta-title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: -0.03em;
  margin-bottom: 0;
}

.cta-desc {
  margin-bottom: 1.5rem;
}

.cta-horizontal {
  .form-control {
    flex-grow: 1;
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .cta-desc {
    margin-bottom: 0;
  }
}

.cta-horizontal-box {
  padding-top: 5.2rem;
  padding-bottom: 4.8rem;
}

.cta-heading {
  padding-top: 0.7rem;
  margin-bottom: 2.7rem;

  .cta-title {
    margin-bottom: 0.5rem;
  }
}

.cta-text {
  padding-top: 0.5rem;

  .cta-title {
    margin-bottom: 2.1rem;
  }

  .cta-desc {
    margin-bottom: 2.3rem;
  }
}

.cta-separator {
  position: relative;

  [class*="col-"] {
    + {
      [class*="col-"] {
        &:before {
          content: "";
          width: 0.1rem;
          display: block;
          height: 80%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          background-color: #ebebeb;
        }
      }
    }
  }
}

.cta-separator.bg-image {
  [class*="col-"] {
    + {
      [class*="col-"] {
        &:before {
          background-color: #dadada;
        }
      }
    }
  }
}

.cta-wrapper {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;

  .cta-title {
    margin-bottom: 0.6rem;
  }

  .cta-desc {
    margin-bottom: 2rem;
  }
}

.cta-wrapper.cta-text {
  max-width: 330px;

  .cta-title {
    margin-bottom: 1.6rem;
  }

  .cta-desc {
    margin-bottom: 2.6rem;
  }
}

.cta-border {
  padding: 3.9rem 2rem;
  border: 1rem solid #f5f5f5;
  text-align: center;

  .cta-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .cta-heading {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;
    padding-top: 0;
    padding-right: 0;
  }

  .cta-title {
    margin: 0 0 2rem;
  }

  .cta-text {
    position: relative;
    padding: 0;
    flex-grow: 1;
    margin-bottom: 2rem;

    &::before {
      content: "";
      background-color: #f5f5f5;
      width: 0.5rem;
      height: 9rem;
      position: absolute;
      left: -3px;
      top: 50%;
      margin-top: -4.5rem;
      display: none;
    }
  }

  p {
    max-width: 460px;
    margin-bottom: 0;
  }
}

.count-container {
  margin-bottom: 3.3rem;
}

.count-wrapper {
  color: #c96;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 0.6rem;
}

.count-title {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}

.pagination {
  border-radius: 0;
  align-items: center;
  margin-bottom: 2rem;
}

.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  height: 4rem;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 0 1rem;
  line-height: 1;
  color: #706f6c;
  border-radius: 0.3rem;
  background-color: transparent;
  border: 0.1rem solid transparent;
  cursor: pointer;

  i {
    font-size: 1.5rem;
  }

  &:hover {
    box-shadow: none;
    color: var(--theme-color);
    border-color: #ebebeb;
    text-decoration: none;
    background-color: transparent;
    outline: none !important;
  }

  &:focus {
    box-shadow: none;
    color: var(--theme-color);
    border-color: #ebebeb;
    text-decoration: none;
    background-color: transparent;
    outline: none !important;
  }
}

.page-link.page-link-prev {
  margin-right: 1.7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 70px;

  i {
    margin-left: 0;
    margin-right: 1rem;
  }
}

.page-link.page-link-next {
  margin-left: 1.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 70px;

  i {
    margin-left: 1rem;
    margin-right: 0;
  }
}

.page-item-total {
  color: #cccccc;
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 1.2rem;
}

.page-item {
  + {
    .page-item {
      margin-left: 0.4rem;
    }
  }

  &:first-child {
    .page-link {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }
  }

  &:last-child {
    .page-link {
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }
}

.page-item.active {
  .page-link {
    color: #c96;
    background-color: transparent;
    border-color: #ebebeb;
  }
}

.page-item.disabled {
  .page-link {
    border-color: transparent;
    color: #eaeaea;
  }
}

.modal-backdrop {
  background-color: #333;
}

.modal-backdrop.show {
  opacity: 0.4;
}

.modal-body {
  padding: 0;

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
}

.modal-content {
  border: none;
  box-shadow: 5px 10px 16px rgba(51, 51, 51, 0.05), -5px 10px 16px rgba(51, 51, 51, 0.05);
}

.close {
  font-weight: normal;
  font-size: 1.8rem;
  color: #666666;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    color: #c96;
  }

  &:focus {
    color: #c96;
  }
}

.alert {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.01em;
  border-radius: 0;
  margin: 0;
  padding: 1rem 1.5rem;
  border: none;

  .container {
    position: relative;
  }

  a {
    color: rgba(255, 255, 255, 0.7);

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }

    &:focus {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .close {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1;
    top: 50%;
    transform: translateY(-50%);

    span {
      display: inline-block;
      line-height: 1;
      padding-bottom: 0.2rem;
    }
  }
}

.alert-primary {
  color: #fff;
  background-color: #c96;
}

.alert-dark {
  color: #fff;
  background-color: #333;
}

.alert-danger {
  color: #fff;
  background-color: #da2828;
}

.alert-info {
  color: #fff;
  background-color: #32b1d6;
}

.alert-success {
  color: #fff;
  background-color: #20cb55;
}

.alert-warning {
  color: #fff;
  background-color: #ea8745;
}

.instagram-feed {
  width: 100%;
  display: block;
  position: relative;
  background-color: #ccc;
  margin-bottom: 2rem;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.4);
    transition: all 0.4s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }

  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
    }

    .instagram-feed-content {
      opacity: 1;
      visibility: visible;
    }
  }

  img {
    max-width: none;
    width: 100%;
  }
}

.instagram-feed-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  font-weight: 400;
  font-size: 1.6rem;
  transition: all 0.45s ease;
  opacity: 0;
  visibility: hidden;

  i {
    font-weight: normal;
    margin-right: 0.8rem;
  }

  a {
    color: inherit;

    &:hover {
      color: #c96;
    }

    &:focus {
      color: #c96;
    }

    + {
      a {
        margin-left: 2rem;
      }
    }
  }
}

.elements {
  > div {
    padding: 1rem;

    &:nth-child(1) {
      .element-img {
        background-position: calc(-200px * calc(1 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 1 + 100px) 0;
      }
    }

    &:nth-child(2) {
      .element-img {
        background-position: calc(-200px * calc(2 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 2 + 100px) 0;
      }
    }

    &:nth-child(3) {
      .element-img {
        background-position: calc(-200px * calc(3 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 3 + 100px) 0;
      }
    }

    &:nth-child(4) {
      .element-img {
        background-position: calc(-200px * calc(4 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 4 + 100px) 0;
      }
    }

    &:nth-child(5) {
      .element-img {
        background-position: calc(-200px * calc(5 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 5 + 100px) 0;
      }
    }

    &:nth-child(6) {
      .element-img {
        background-position: calc(-200px * calc(6 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 6 + 100px) 0;
      }
    }

    &:nth-child(7) {
      .element-img {
        background-position: calc(-200px * calc(7 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 7 + 100px) 0;
      }
    }

    &:nth-child(8) {
      .element-img {
        background-position: calc(-200px * calc(8 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 8 + 100px) 0;
      }
    }

    &:nth-child(9) {
      .element-img {
        background-position: calc(-200px * calc(9 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 9 + 100px) 0;
      }
    }

    &:nth-child(10) {
      .element-img {
        background-position: calc(-200px * calc(10 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 10 + 100px) 0;
      }
    }

    &:nth-child(11) {
      .element-img {
        background-position: calc(-200px * calc(11 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 11 + 100px) 0;
      }
    }

    &:nth-child(12) {
      .element-img {
        background-position: calc(-200px * calc(12 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 12 + 100px) 0;
      }
    }

    &:nth-child(13) {
      .element-img {
        background-position: calc(-200px * calc(13 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 13 + 100px) 0;
      }
    }

    &:nth-child(14) {
      .element-img {
        background-position: calc(-200px * calc(14 - 1)) 0;
      }

      .element-hover-img {
        background-position: calc(-200px * 14 + 100px) 0;
      }
    }

    > a {
      display: block;
    }
  }
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #ebebeb;
  position: relative;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 5px 20px rgba(234, 234, 234, 0.8);

    .element-hover-img {
      visibility: visible;
      opacity: 1;
    }

    .element-img {
      opacity: 0;
    }

    p {
      color: #333;
    }
  }

  &:focus {
    box-shadow: 0 5px 20px rgba(234, 234, 234, 0.8);

    .element-hover-img {
      visibility: visible;
      opacity: 1;
    }

    .element-img {
      opacity: 0;
    }

    p {
      color: #333;
    }
  }

  p {
    margin-top: -1rem;
    padding-bottom: 1rem;
  }
}

.element-img {
  width: 100px;
  height: 100px;
  background-image: url(../images/elements.png);
  background-repeat: no-repeat;
  transition: all 0.3s;
}

.element-hover-img {
  width: 100px;
  height: 100px;
  background-image: url(../images/elements.png);
  background-repeat: no-repeat;
  transition: all 0.3s;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
}

.element-type {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Poppins", serif;
  letter-spacing: 0.01em;
  color: #666;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .page-header {
    h1 {
      font-size: 3.6rem;

      span {
        font-size: 1.8rem;
      }
    }
  }
  .page-header.page-header-big {
    min-height: 350px;
  }
  .title {
    font-size: 2.3rem;
    font-weight: 600;
  }
  .title-lg {
    font-size: 2.6rem;
  }
  .icon-box {
    margin-bottom: 4rem;
  }
  .icon-box-side {
    margin-bottom: 0;
  }
  .just-action-icons-md {
    .btn-product {
      span {
        display: none;
      }

      &:before {
        margin-right: 0;
      }
    }
  }
  .banner-content {
    left: 3rem;
  }
  .banner-big {
    .banner-content {
      left: 6rem;
    }

    .banner-title {
      font-size: 4rem;
    }
  }
  .banner-display {
    .banner-title {
      font-size: 2.4rem;
    }
  }
  .video-banner-bg {
    padding-top: 14.5rem;
    padding-bottom: 13.5rem;
  }
  .video-banner-title {
    > span {
      font-size: 2.4rem;
    }
  }
  .video-box-content {
    .video-banner-title {
      span {
        font-size: 2rem;
      }
    }
  }
  .btn-video {
    width: 7rem;
    height: 7rem;
    font-size: 1.8rem;
  }
  .modal-dialog {
    max-width: 575px;
  }
}

@media screen and (min-width: 992px) {
  .page-header {
    h1 {
      font-size: 4rem;

      span {
        font-size: 2rem;
      }
    }
  }
  .page-header.page-header-big {
    min-height: 450px;
  }
  .heading.heading-flex {
    align-items: center;
    flex-direction: row;
    text-align: left;
  }
  .heading-right {
    margin-top: 0;
    margin-left: auto;
  }
  .card-title {
    font-size: 2rem;
  }
  .nav-pills {
    + {
      .tab-content {
        .tab-pane {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }
  .just-action-icons-lg {
    .btn-product {
      span {
        display: none;
      }

      &:before {
        margin-right: 0;
      }
    }
  }
  .banner-content {
    left: 4rem;
  }
  .banner-big {
    .banner-content {
      left: 10rem;
    }

    .banner-title {
      font-size: 5rem;
    }
  }
  .video-banner-bg {
    padding-top: 18.5rem;
    padding-bottom: 17.5rem;
  }
  .video-box-content {
    padding-left: 5rem;
    padding-left: 7rem;
  }
  .video-fullheight {
    height: 100vh;
    max-height: 900px;
  }
  .cta-border {
    text-align: left;
    padding-left: 0;
    padding-right: 0;

    .cta-content {
      flex-direction: row;
      padding-left: 1.5rem;
    }

    .cta-heading {
      flex: 0 0 24.7%;
      max-width: 24.7%;
      padding-right: 3rem;
    }

    .cta-title {
      margin-bottom: 0;
    }

    .cta-text {
      margin-right: 2rem;
      padding-left: 3rem;
      margin-bottom: 0;

      &::before {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .icon-boxes-separator {
    [class*="col-"] {
      + {
        [class*="col-"] {
          &:before {
            content: "";
            display: block;
            width: 0.1rem;
            height: 5.3rem;
            background-color: #e9e9e9;
            position: absolute;
            left: -0.3rem;
            top: 50%;
            margin-top: -2.55rem;
          }
        }
      }
    }
  }
  .just-action-icons-xl {
    .btn-product {
      span {
        display: none;
      }

      &:before {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .icon-box-sm {
    br {
      display: none;
    }
  }
  .cta-horizontal-box {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (min-width: 480px) {
  .just-action-icons-sm {
    .btn-product {
      span {
        display: none;
      }

      &:before {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .product.product-9 {
    &:hover {
      .product-body {
        transform: translateY(-60%);
      }

      .product-action {
        transform: translateY(-60%);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .sticky-wrapper {
    z-index: 1;
  }
  .cta-horizontal {
    .cta-desc {
      margin-bottom: 1.5rem;
    }
  }
  .cta-separator {
    [class*="col-"] {
      + {
        [class*="col-"] {
          &:before {
            height: 0.1rem;
            width: 80%;
            left: 10%;
            top: 0;
            bottom: auto;
          }
        }
      }
    }
  }
  .cta-border {
    .cta-title {
      text-align: center !important;
    }

    .cta-title.text-right {
      text-align: center !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .cta-horizontal {
    .form-control {
      margin-right: 1rem;
    }
  }
  .cta {
    .btn {
      &:not(.btn-block) {
        min-width: 0;
      }
    }
  }
  .cta-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .cta {
    .input-group {
      flex-direction: column;

      .form-control {
        width: 100%;
        margin-right: 0;
      }

      .btn {
        margin-left: 0;
        margin-top: 1rem;
      }

      .input-group-append {
        justify-content: center;
      }

      .input-group-prepend {
        justify-content: center;
      }
    }

    .input-group-round {
      .form-control {
        border-radius: 30px;
      }

      .btn {
        border-radius: 30px;
      }

      .input-group-append {
        justify-content: center;
      }
    }
  }
}
