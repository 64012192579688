// .preloader {
//   background-color: hsla(0, 0%, 100%, 0.8);
//   bottom: 0;
//   height: 100%;
//   left: 0;
//   position: fixed;
//   right: 0;
//   top: 0;
//   width: 100%;
//   z-index: 9999;
// }

.preloader {
  background-color: hsla(0, 0%, 100%, 0.95);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.lds-ellipsis {
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 64px;
  text-align: center;
  z-index: 9999;
}
