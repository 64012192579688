.swal-footer {
  text-align: center;
  margin-top: 12px;
  padding: 12px 15px;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .swal-button {
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 25px;
  }
}

.swal-title {
  font-weight: 500;
  padding: 13px 16px;
  font-size: 24px;
}