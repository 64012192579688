html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  font: normal 400 1.4rem/1.86 "Barlow", Arial, Helvetica, sans-serif;
  color: #333;
  background-color: #eee;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

::-moz-selection,
::selection {
  color: #fff;
  background-color: #cc9966;
}

p {
  margin-bottom: 1.5rem;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

b,
strong {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

hr {
  border: none;
  border-top: 1px solid #ebebeb;
  margin: 3rem auto 2.5rem;
}

sub {
  position: relative;
  font-size: 70%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  position: relative;
  font-size: 70%;
  line-height: 0;
  vertical-align: baseline;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

.bg-light-2 {
  background-color: #f9f9f9 !important;
}

.bg-lighter {
  background-color: #fafafa !important;
}

.bg-gray {
  background-color: #f4f5f8 !important;
}

.bg-primary {
  background-color: #e96d28 !important;
}

.bg-secondary {
  background-color: #a6c76c !important;
}

.bg-image {
  background-color: #ccc;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-overlay {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.3;
    background-color: #333;
  }

  .container,
  .container-fluid {
    position: relative;
    z-index: 2;
  }
}

/* Animation for lazyload */
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Type */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: #333333;
  letter-spacing: -0.03em;
}

h1,
.h1 {
  font-size: 4rem;
}

h2,
.h2 {
  font-size: 3.6rem;
}

h3,
.h3 {
  font-size: 2.6rem;
}

h4,
.h4 {
  font-size: 2.2rem;
}

h5,
.h5 {
  font-size: 2rem;
  letter-spacing: -0.025em;
}

h6,
.h6 {
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}

a {
  color: #cc9966;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: #bf8040;
    text-decoration: none;
  }
}

.link-underline {
  box-shadow: 0 1px 0 0 #cc9966;
}

.text-primary {
  color: #cc9966 !important;
}

.text-secondary {
  color: #a6c76c !important;
}

.text-dark {
  color: #333 !important;
}

.text-light {
  color: #ccc !important;
}

.lead {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.875;
  margin-bottom: 1.2rem;
}

blockquote {
  position: relative;
  margin: 0 0 4.2rem;
  padding: 0;
  color: #777;
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.625;

  p {
    max-width: 1120px;
  }

  &.text-center p {
    margin-left: auto;
    margin-right: auto;
  }

  cite {
    font-style: normal;
    font-weight: 500;
    color: #333333;
    letter-spacing: -0.01em;
    line-height: 1;

    span {
      display: block;
      font-weight: 300;
      font-size: 1.4rem;
    }
  }

  &.text-white cite {
    color: #cccccc;

    span {
      color: #777;
    }
  }
}

@media screen and (min-width: 768px) {
  h1,
  .h1 {
    font-size: 3rem;
  }

  h2,
  .h2 {
    font-size: 2rem;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
  }

  h4,
  .h4 {
    font-size: 1rem;
  }
}

/* Layout */

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;

  > {
    .col,
    [class*="col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.container {
  max-width: 1400px;
  padding-left: 15px;
  padding-right: 15px;
}

.page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  transition: transform 0.4s ease;
}

.mmenu-active .page-wrapper {
  // transform: translateX(200px);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page-wrapper {
    display: block;
  }
}

.main {
  flex: 1;
  width: 100%;
}

.page-content {
  padding-bottom: 5rem;
}

@media screen and (min-width: 992px) {
  .col-5col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.col-xl-5col,
.col-xl-4-5col,
.col-xl-2-5col,
.col-xl-3-5col {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 1200px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .col-xl-5col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xl-4-5col {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  .col-xl-2-5col {
    -ms-flex: 0 0 calc(40%);
    flex: 0 0 calc(40%);
    max-width: calc(40%);
  }

  .col-xl-3-5col {
    -ms-flex: 0 0 calc(60%);
    flex: 0 0 calc(60%);
    max-width: calc(60%);
  }

  .no-gutters {
    .col-xl-2-5col {
      -ms-flex: 0 0 calc(40% + 8px);
      flex: 0 0 calc(40% + 8px);
      max-width: calc(40% + 8px);
    }

    .col-xl-3-5col {
      -ms-flex: 0 0 calc(60% - 8px);
      flex: 0 0 calc(60% - 8px);
      max-width: calc(60% - 8px);
    }
  }
}

@media screen and (min-width: 1280px) {
  .container {
    max-width: 1400px;
  }
}

/* Extend grid - 1600px */
@media screen and (min-width: 1600px) {
  .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
  }

  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .offset-xxl-2 {
    margin-left: 16.666667%;
  }

  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xxl-5col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xxl-4-5col {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }
}

@media screen and (min-width: 1920px) {
  .container-fluid {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}
