:root {
  --theme-color: #fe6f03;
  --theme-hi-color: #fc4a1a;
  --theme-icon-color: #fe6f03;
  --theme-hover-color: #cd6110;
  --button-bg-color: #fe6f03;
  --button-bg-hover-color: #ff7207;
  --button-font-color: #fff;
  --button-font-hover-color: #fff;

  --select-text-bg-color: #2196f3;
  --select-text-font-color: #fff;

  --link-color: #555555;
  --link-hover-color: #fe6f03;

  --product-title-link-color: #333;
  --product-title-link-hover-color: #fe6f03;

  --footer-bg-color: #fe6f03;
  --footer-text-color: #000;
  --footer-icon-color: #000;
  --footer-hover-color: #000;
  --primary-color-dark: #fc4a1a;
  --primary-color-light: #f7b733;
  --primary-color-lighter: #ffebdc;
  --primary-color-green: green;
}

$baseColor: #fe6f03;
$baseFontColor: #fff;

$baseHoverColor: #cd6110;
$baseHoverFontColor: #fff;

$LinkColor: #fe6f03;
$darkOrange: #fc4a1a;

$selectAttributeBorderColor: #fe6f03;
$dark1: #000000;
$white: #fff;
